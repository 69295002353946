import React from "react";

function LoadingTicketList() {
  return (
    <table className="min-w-full text-left text-sm text-gray-500">
      <thead className="text-xs uppercase bg-gray-200 text-gray-600">
        <tr>
          <th className="px-4 py-3">Avatar</th>
          <th className="px-4 py-3">Priority</th>
          <th className="px-4 py-3">Title</th>
          <th className="px-4 py-3">Description</th>
          <th className="px-4 py-3">Sender</th>
          <th className="px-4 py-3">Created At</th>
          <th className="px-4 py-3">Status</th>
          <th className="px-4 py-3">Image</th>
        </tr>
      </thead>
      <tbody>
        {[...Array(3)].map((_, index) => (
          <tr
            key={index}
            className="border border-gray-300 shadow-md rounded-md bg-white"
          >
            <td className="px-4 py-2 sm:px-6">
              <div className="animate-pulse flex items-center space-x-4">
                <div className="rounded-full bg-gray-300 h-10 w-10"></div>
                <div className="flex-1 space-y-4 py-1">
                  <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                  <div className="space-y-2">
                    <div className="h-4 bg-gray-300 rounded w-full"></div>
                    <div className="h-4 bg-gray-300 rounded w-5/6"></div>
                  </div>
                </div>
              </div>
            </td>
            <td className="px-4 py-2 sm:px-6">
              <div className="h-4 bg-gray-300 rounded w-2/4 animate-pulse"></div>
            </td>
            <td className="px-4 py-2 sm:px-6">
              <div className="h-4 bg-gray-300 rounded w-full animate-pulse"></div>
            </td>
            <td className="px-4 py-2 sm:px-6">
              <div className="h-4 bg-gray-300 rounded w-3/4 animate-pulse"></div>
            </td>
            <td className="px-4 py-2 sm:px-6">
              <div className="h-4 bg-gray-300 rounded w-full animate-pulse"></div>
            </td>
            <td className="px-4 py-2 sm:px-6">
              <div className="h-4 bg-gray-300 rounded w-1/2 animate-pulse"></div>
            </td>
            <td className="px-4 py-2 sm:px-6">
              <div className="h-4 bg-gray-300 rounded w-1/2 animate-pulse"></div>
            </td>
            <td className="px-4 py-2 sm:px-6">
              <div className="h-4 bg-gray-300 rounded w-1/4 animate-pulse"></div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default LoadingTicketList;
