// export const serverIP = "https://ticket-system-server-kzww.vercel.app/";

export const serverIP = process.env.REACT_APP_SERVER_IP;

export const fetchTicketDetails = async (ticketId, token) => {
  try {
    const response = await fetch(`${serverIP}api/tickets/${ticketId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching ticket details: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching ticket details:", error);
    throw error;
  }
};

export const fetchTicketMessages = async (ticketId, token) => {
  try {
    const response = await fetch(
      `${serverIP}api/tickets/${ticketId}/messages`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error fetching messages: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching messages:", error);
    throw error;
  }
};

export const sendMessage = async (ticketId, messageObject, token) => {
  try {
    const response = await fetch(
      `${serverIP}api/tickets/${ticketId}/messages`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageObject),
      }
    );

    if (!response.ok) {
      throw new Error(`Error sending message: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const fetchTreeData = async (token) => {
  try {
    const response = await fetch(`${serverIP}api/tree-data`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch tree data");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching tree data:", error);
    throw error;
  }
};

export const fetchCountsTree = async (token) => {
  try {
    const response = await fetch(`${serverIP}api/counts`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch counts");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching counts:", error);
    throw error;
  }
};

export const fetchDepartments = async (token) => {
  try {
    const response = await fetch(`${serverIP}api/departments`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch departments");
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to fetch departments", error);
    throw error;
  }
};

export const createDepartment = async (name, description, token) => {
  try {
    const response = await fetch(`${serverIP}api/departments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name, description }),
    });
    if (!response.ok) {
      throw new Error("Failed to create department");
    }
    return await response.json();
  } catch (error) {
    console.error("Failed to create department", error);
    throw error;
  }
};

export const deleteDepartment = async (id, token) => {
  try {
    const response = await fetch(`${serverIP}api/departments/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to delete department");
    }
  } catch (error) {
    console.error("Failed to delete department", error);
    throw error;
  }
};

export const handleForwardToUsers = async (
  ticketId,
  userIds,
  token,
  setLoading
) => {
  if (!ticketId || !userIds.length) {
    alert("Please select a ticket and at least one user");
    return;
  }

  setLoading(true);

  try {
    const response = await fetch(`${serverIP}api/tickets/${ticketId}/forward`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ recipients: userIds }),
    });

    if (response.ok) {
      alert("Ticket forwarded successfully");
    } else {
      const errorData = await response.text();
      console.error("Failed to forward ticket:", response.status, errorData);
      alert("Failed to forward ticket");
    }
  } catch (error) {
    console.error("Error forwarding ticket", error);
    alert("Failed to forward ticket");
  } finally {
    setLoading(false);
  }
};

// Fetch all subjects
export const fetchSubjects = async (token) => {
  try {
    const response = await fetch(`${serverIP}api/subjects`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch subjects");
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Create a new subject
export const createSubject = async (name, token) => {
  try {
    const response = await fetch(`${serverIP}api/subjects`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name }),
    });

    if (!response.ok) {
      throw new Error("Failed to create subject");
    }

    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Delete a subject by ID
export const deleteSubject = async (id, token) => {
  try {
    const response = await fetch(`${serverIP}api/subjects/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to delete subject");
    }

    // Optionally, return a success message
    return "Subject deleted successfully";
  } catch (error) {
    console.error(error);
    throw error; // Rethrow the error to handle it in the calling function
  }
};
