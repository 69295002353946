import React, { useState, useEffect } from "react";
import { useAuth } from "../../auth";
import { useDropzone } from "react-dropzone";
import IconClose from "../SVG/IconClose";
import { serverIP } from "../../api";

const UserAddTicket = ({ onClose }) => {
  const { userId, token } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    sender_id: userId,
    assigned_department: "",
    priority: "Medium",
    status: "new",
    subject: "",
  });
  const [files, setFiles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDepartments();
    fetchSubjects();
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      setFormData((prev) => ({
        ...prev,
        assigned_department: selectedDepartment,
      }));
    }
  }, [selectedDepartment]);

  const fetchDepartments = async () => {
    try {
      const response = await fetch(`${serverIP}api/departments`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setDepartments(data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };
  const fetchSubjects = async () => {
    // Fetch subjects from the API
    try {
      const response = await fetch(`${serverIP}api/subjects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setSubjects(data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      setError(
        "Some files were rejected. Please make sure you upload valid file formats."
      );
    } else {
      setError("");
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: file.type.startsWith("image/")
            ? URL.createObjectURL(file)
            : null, // Only create preview for images
        })
      );
      setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append new files
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {}, // Allow all file types
    multiple: true,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true
    const formDataWithFiles = new FormData();
    Object.keys(formData).forEach((key) =>
      formDataWithFiles.append(key, formData[key])
    );

    // Append both images and other files to the FormData
    files.forEach((file) => formDataWithFiles.append("files", file)); // Ensure "files" matches the backend field

    try {
      const response = await fetch(`${serverIP}api/addTicket`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataWithFiles,
      });

      if (response.ok) {
        alert("Ticket created successfully");
        setFormData({
          name: "",
          description: "",
          sender_id: userId,
          assigned_department: "",
          priority: "Medium",
          subject: "",
        });
        setFiles([]);
        setSelectedDepartment("");
        onClose();
      } else {
        const errorData = await response.json();
        alert(`Failed to create ticket: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error creating ticket", error);
      alert("Failed to create ticket");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex z-50 justify-center  items-center  ">
      <div className="relative max-w-lg w-full bg-white text-black p-8 rounded-lg shadow-lg">
        <IconClose
          onClick={onClose}
          className="absolute top-2 right-2 w-8 h-8 text-gray-500 hover:text-gray-700 focus:outline-none"
        />
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">
          Create Ticket
        </h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="relative h-11 w-full min-w-[200px] mb-4">
            <input
              placeholder=""
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              maxLength={60}
              className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
            />
            <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Title
            </label>
          </div>

          <div className="relative h-28 mt-4 w-full min-w-[200px] mb-4">
            <textarea
              placeholder=""
              name="description"
              id="description"
              value={formData.description}
              onChange={handleChange}
              maxLength={250}
              className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-none disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100 resize-none"
              required
            ></textarea>
            <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Description
            </label>
          </div>

          <div className="flex space-x-4 mt-4 mb-4">
            {/* Priority selection */}
            <div className="relative h-11 w-full min-w-[200px]">
              <select
                id="priority"
                name="priority"
                value={formData.priority}
                onChange={handleChange}
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 text-blue-gray-700 outline-none transition-all focus:border-yellow-400"
                required
              >
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
              <label className="absolute left-0 -top-1.5 text-gray-500 peer-focus:text-[12px] peer-focus:text-gray-900">
                Priority
              </label>
            </div>

            {/* Subject selection */}
            <div className="relative h-11 w-full min-w-[200px]">
              <select
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 text-blue-gray-700 outline-none transition-all focus:border-yellow-400"
                required
              >
                <option value="">Select Subject</option>
                {subjects.map((subject) => (
                  <option key={subject.id} value={subject.subject_name}>
                    {subject.subject_name}
                  </option>
                ))}
              </select>
              <label className="absolute left-0 -top-1.5 text-gray-500 peer-focus:text-[12px] peer-focus:text-gray-900">
                Subject
              </label>
            </div>
          </div>
          <div className="relative h-11 w-full min-w-[200px] mt-4 mb-4">
            <select
              id="department"
              name="assigned_department"
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
              className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 text-blue-gray-700 outline-none transition-all focus:border-yellow-500"
              required
            >
              <option value="" disabled>
                Select Department
              </option>
              {departments.map((dept) => (
                <option key={dept.id} value={dept.id}>
                  {dept.name}
                </option>
              ))}
            </select>

            <label className="absolute left-0 -top-1.5 text-gray-500 peer-focus:text-[12px] peer-focus:text-gray-900">
              Department
            </label>
          </div>

          <div
            {...getRootProps({
              className:
                "dropzone p-4 border-2 border-dashed border-gray-300 rounded-lg text-center cursor-pointer mb-4 hover:bg-gray-50 transition",
            })}
          >
            <input {...getInputProps()} />
            <p className="text-gray-500">
              Drag & drop files here, or click to select files (Images & other
              files)
            </p>
          </div>

          <div className="flex flex-wrap gap-2 mb-6">
            {files.map((file) => (
              <div key={file.name} className="w-24 h-24 relative">
                {file.preview ? (
                  <img
                    src={file.preview}
                    alt={file.name}
                    className="w-full h-full object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-200 text-sm flex items-center justify-center rounded-lg p-2">
                    <div className="max-w-full truncate" title={file.name}>
                      {file.name}
                    </div>
                  </div>
                )}
                <button
                  type="button"
                  className="absolute top-0 right-0 text-red-600 font-bold bg-white rounded-full p-1 hover:bg-red-100 focus:outline-none"
                  onClick={() => setFiles(files.filter((f) => f !== file))}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>

          {/* Loading Indicator */}
          {loading ? (
            <div className="flex justify-center mb-4">
              <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 border-t-yellow-400 rounded-full"></div>
              <span className="ml-2 text-gray-500">Submitting...</span>
            </div>
          ) : (
            <>
              <div className="flex justify-end mt-6">
                <button
                  type="button"
                  onClick={onClose}
                  className="mr-4 px-4 py-2 bg-gray-300 hover:bg-gray-400 text-gray-700 font-semibold rounded-md focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading} // Disable button when loading
                  className={`px-4 py-2 bg-yellow-400 hover:bg-yellow-500 text-white font-semibold rounded-md focus:outline-none ${
                    loading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default UserAddTicket;
