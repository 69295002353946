import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./Routes/LoginPage";
import AdminPage from "./Routes/AdminPage";
import UserInbox from "./components/user/UserInbox";
import UserPage from "./Routes/UserPage";
import { useAuth } from "./auth";
import UserOpenTickets from "./components/user/UserOpenTickets";
import ManagerPage from "./Routes/ManagerPage";
import ManagerUsersPage from "./components/manager/ManagerUsersPage";
import ManagerResolvedTickets from "./components/manager/ManagerResolvedTickets";
import AdminOverView from "./components/admin/AdminOverView";
import AdminReports from "./components/admin/AdminReports";
import ManagerCreatedTickets from "./components/manager/ManagerCreatedTickets";
import ManagerInbox from "./components/manager/ManagerInbox";
import AdminUsersPage from "./components/admin/AdminUsersPage";
import AdminTickets from "./components/admin/AdminTickets";

const ProtectedRoute = ({ element, roles }) => {
  const { token, userRole } = useAuth();
  if (!token) {
    return <Navigate to="/" />;
  }
  if (roles && !roles.includes(userRole)) {
    return <Navigate to="/" />;
  }
  return element;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/admin"
          element={<ProtectedRoute element={<AdminPage />} roles={["admin"]} />}
        />
        <Route
          path="/admin/users"
          element={
            <ProtectedRoute element={<AdminUsersPage />} roles={["admin"]} />
          }
        />
        <Route
          path="/admin/overview"
          element={
            <ProtectedRoute element={<AdminOverView />} roles={["admin"]} />
          }
        />
        <Route
          path="/admin/tickets"
          element={
            <ProtectedRoute element={<AdminTickets />} roles={["admin"]} />
          }
        />
        <Route
          path="/admin/reports"
          element={
            <ProtectedRoute element={<AdminReports />} roles={["admin"]} />
          }
        />

        <Route
          path="/manager"
          element={
            <ProtectedRoute element={<ManagerPage />} roles={["manager"]} />
          }
        />
        <Route
          path="/manager/inbox"
          element={
            <ProtectedRoute element={<ManagerInbox />} roles={["manager"]} />
          }
        />
        <Route
          path="/manager/created"
          element={
            <ProtectedRoute
              element={<ManagerCreatedTickets />}
              roles={["manager"]}
            />
          }
        />
        <Route
          path="/manager/users"
          element={
            <ProtectedRoute
              element={<ManagerUsersPage />}
              roles={["manager"]}
            />
          }
        />
        <Route
          path="/manager/resolved"
          element={
            <ProtectedRoute
              element={<ManagerResolvedTickets />}
              roles={["manager"]}
            />
          }
        />
        <Route
          path="/user"
          element={<ProtectedRoute element={<UserPage />} roles={["user"]} />}
        />
        <Route
          path="/user/inbox"
          element={<ProtectedRoute element={<UserInbox />} roles={["user"]} />}
        />
        <Route
          path="/user/opentickets"
          element={
            <ProtectedRoute element={<UserOpenTickets />} roles={["user"]} />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
