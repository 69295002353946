import React, { useEffect, useState } from "react";

import ImageModal from "../Modals/ImageModal";
import { serverIP } from "../api";

export const TicketFiles = ({ ticketId }) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [selectedImageSrc, setSelectedImageSrc] = useState("");
  const [imageModalOpen, setImageModalOpen] = useState(false);
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(
          `${serverIP}api/tickets/${ticketId}/files`
        );
        if (!response.ok) throw new Error("Failed to fetch files");

        const data = await response.json();
        setFiles(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchFiles();
  }, [ticketId]);

  if (error) return <div>Error: {error}</div>;

  const isImageFile = (filename) => {
    if (!filename) return false; // Check if filename is defined
    const extension = filename.split(".").pop().toLowerCase();
    return ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(extension);
  };

  const openImageModal = (imageSrc) => {
    setSelectedImageSrc(imageSrc);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
    setSelectedImageSrc("");
  };
  return (
    <div className="ticket-files">
      <div className="flex flex-wrap mb-4">
        {files
          .filter((file) => isImageFile(file.file_path))
          .map((file, index) => (
            <div key={index} className="file-item m-1">
              <img
                src={`${serverIP}${file.file_path}`}
                alt="Ticket"
                onClick={() => openImageModal(`${serverIP}${file.file_path}`)}
                className="cursor-pointer w-16 h-16 object-cover rounded-full"
              />
            </div>
          ))}
      </div>

      {files
        .filter((file) => !isImageFile(file.file_path))
        .map((file, index) => (
          <div key={index} className="file-item mb-2">
            <a
              href={`${serverIP}${file.file_path}`}
              download
              target="_blank"
              rel="noopener noreferrer"
              className="file-download flex items-center space-x-2 p-2 bg-yellow-400 text-white rounded hover:bg-yellow-500 transition duration-200"
            >
              <span>{file.file_path.split(`uploads/`)[1]}</span>
            </a>
          </div>
        ))}

      {imageModalOpen && (
        <ImageModal
          isOpen={imageModalOpen}
          onClose={closeImageModal}
          imageSrc={selectedImageSrc}
        />
      )}
    </div>
  );
};
