import React from "react";
import IconClose from "../components/SVG/IconClose";

const ImageModal = ({ isOpen, onClose, imageSrc }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto">
      <div className="relative bg-white p-4 rounded-lg max-w-3xl max-h-[90vh] mx-auto overflow-auto">
        <button onClick={onClose} className="absolute top-1 right-1">
          <IconClose className="w-8 h-8 " />
        </button>

        <img
          src={imageSrc}
          alt="Full size"
          className="max-w-full max-h-[80vh] object-contain"
        />
      </div>
    </div>
  );
};

export default ImageModal;
