import React, { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../auth";
import Navbar from "../Navbar";
import { formatDate } from "../../tools/Tools";
import ImageModal from "../../Modals/ImageModal";
import ChatPage from "../general/ChatPage";
import { CSVLink } from "react-csv";
import IconExcel from "../SVG/IconExcel";
import IconSearch from "../SVG/IconSearch";
import IconReport from "../SVG/IconReport";
import TicketsList from "../TicketsList";
import { Sidebar } from "../Sidebar";
import { serverIP } from "../../api";
const ReportsPage = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deptCreated, setDeptCreated] = useState("");
  const [deptReceived, setDeptReceived] = useState("");
  const [status, setStatus] = useState("");
  const [departments, setDepartments] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage] = useState(10);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState("");
  const [chatPageOpen, setChatPageOpen] = useState(false);
  const [chatTicketId, setChatTicketId] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const { token } = useAuth();
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");

  useEffect(() => {
    const fetchDepartmentsAndSubjects = async () => {
      try {
        const departmentsResponse = await fetch(`${serverIP}api/departments`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!departmentsResponse.ok) {
          throw new Error("Failed to fetch departments");
        }
        const departmentsData = await departmentsResponse.json();
        setDepartments(departmentsData);

        const subjectsResponse = await fetch(`${serverIP}api/subjects`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!subjectsResponse.ok) {
          throw new Error("Failed to fetch subjects");
        }
        const subjectsData = await subjectsResponse.json();
        setSubjects(subjectsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDepartmentsAndSubjects();
  }, [token]);

  const fetchTickets = useCallback(async () => {
    const queryParams = new URLSearchParams();

    if (startDate) queryParams.append("startDate", startDate);
    if (endDate) queryParams.append("endDate", endDate);
    if (deptCreated) queryParams.append("deptCreated", deptCreated);
    if (deptReceived) queryParams.append("deptReceived", deptReceived);
    if (status) queryParams.append("status", status);
    if (selectedSubject) queryParams.append("subject", selectedSubject);

    try {
      const response = await fetch(
        `${serverIP}api/reports/generate?${queryParams.toString()}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error fetching tickets: ${response.statusText}`);
      }

      const data = await response.json();
      setFilteredTickets(data);
    } catch (error) {
      console.error("Error fetching tickets:", error.message || error);
    } finally {
    }
  }, [startDate, endDate, deptCreated, deptReceived, status, token]);

  const handleSearch = () => {
    setCurrentPage(1);
    fetchTickets();
  };

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredTickets.slice(
    indexOfFirstTicket,
    indexOfLastTicket
  );

  const totalPages = Math.ceil(filteredTickets.length / ticketsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };
  const openImageModal = (imageSrc) => {
    setSelectedImageSrc(imageSrc);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
    setSelectedImageSrc("");
  };

  const openChatPage = (ticketId) => {
    setChatTicketId(ticketId);
    setChatPageOpen(true);
  };

  const closeChatPage = () => {
    setChatPageOpen(false);
    setChatTicketId(null);
  };
  const getCSVData = () => {
    return filteredTickets.map((ticket) => ({
      ID: ticket.id,
      Title: ticket.name,
      Description: ticket.description,
      "Created by": `${ticket.sender_name} ${ticket.sender_surname} (${ticket.sender_department_name})`,
      "Assigned Department": ticket.assigned_department_name,
      Recipient: `${ticket.recipient_name} ${ticket.recipient_surname} (${ticket.recipient_department_name})`,
      "Creation Date": formatDate(ticket.creation_date),
      "Closing Date": ticket.closing_date
        ? formatDate(ticket.closing_date)
        : "Not Closed",
    }));
  };
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <Navbar />

        <div className="flex-1 overflow-y-auto p-6 lg:p-12">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-full lg:max-w-6xl mx-auto">
            <h1 className="text-3xl font-bold text-gray-800 mb-6 inline-flex items-center gap-1">
              <IconReport className="w-8 h-8" />
              <span>Reports</span>
            </h1>

            <div className="flex flex-wrap gap-4 mb-6">
              <div className="w-full md:w-1/2 lg:w-1/4 mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">
                  Start Date
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </label>
              </div>

              <div className="w-full md:w-1/2 lg:w-1/4 mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">
                  End Date
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </label>
              </div>

              <div className="w-full md:w-1/2 lg:w-1/4 mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">
                  Created Department
                  <select
                    value={deptCreated}
                    onChange={(e) => setDeptCreated(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Created Department</option>
                    {departments.map((dept) => (
                      <option key={dept.id} value={dept.name}>
                        {dept.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="w-full md:w-1/2 lg:w-1/4 mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">
                  Received Department
                  <select
                    value={deptReceived}
                    onChange={(e) => setDeptReceived(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Received Department</option>
                    {departments.map((dept) => (
                      <option key={dept.id} value={dept.name}>
                        {dept.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="w-full md:w-1/2 lg:w-1/4 mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">
                  Status
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Status</option>
                    <option value="new">NEW</option>
                    <option value="underreview">UNDERREVIEW</option>
                    <option value="confirmed">CONFIRMED</option>
                    <option value="solved">SOLVED</option>
                  </select>
                </label>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 mb-4">
                <label className="block text-gray-700 text-sm font-medium mb-1">
                  Subject
                  <select
                    value={selectedSubject}
                    onChange={(e) => setSelectedSubject(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select Subject</option>
                    {subjects.map((subject) => (
                      <option key={subject.id} value={subject.name}>
                        {subject.subject_name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <div className="w-full flex justify-between items-center mb-6">
                <button
                  onClick={handleSearch}
                  className="bg-yellow-400 text-white px-4 py-2 rounded-md shadow-md hover:bg-yellow-500 inline-flex items-center justify-center gap-2 transition-colors duration-200 ease-in-out"
                >
                  <IconSearch className="w-5 h-5" />
                  <span>Search</span>
                </button>

                <CSVLink
                  data={getCSVData()}
                  filename={"reports.csv"}
                  className="bg-green-500 text-white px-2 py-2 rounded-md shadow-md hover:bg-green-600 inline-flex items-center justify-center gap-2 transition-colors duration-200 ease-in-out"
                >
                  <IconExcel className="w-6 h-6" />
                  <span>Export CSV</span>
                </CSVLink>
              </div>
            </div>

            <div className="overflow-x-auto">
              <TicketsList
                tickets={filteredTickets}
                selectedTicket={selectedTicket}
                setSelectedTicket={setSelectedTicket}
                handleRowDoubleClick={openChatPage}
                openChatPage={openChatPage}
                openImageModal={openImageModal}
                role="admin"
              />
            </div>
            <p className="pt-4 text-lg">
              Total: <span className="font-bold">{filteredTickets.length}</span>{" "}
              tickets found
            </p>
          </div>
        </div>
        {imageModalOpen && (
          <ImageModal
            isOpen={imageModalOpen}
            onClose={closeImageModal}
            imageSrc={selectedImageSrc}
          />
        )}
        {chatPageOpen && (
          <ChatPage ticketId={chatTicketId} onClose={closeChatPage} />
        )}
      </div>
    </div>
  );
};

export default ReportsPage;
