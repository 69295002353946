import React, { useState, useEffect } from "react";
import { useAuth } from "../../auth";
import IconSend from "../SVG/IconSend";
import IconClose from "../SVG/IconClose";
import { formatDate } from "../../tools/Tools";
import {
  fetchTicketDetails,
  fetchTicketMessages,
  sendMessage,
} from "../../api";
import PrintableChatPage from "./PrintableChatPage";
import { IconPrinter } from "../SVG/SVG";
import ProgressBar from "../../tools/ProgressBar";
import IconRefresh from "../SVG/IconRefresh";
import IconAdd from "../SVG/IconAdd";
import { IconCheckmarkDone, IconEye, IconForward } from "../../SVG/svg";
import IconResolvedTicket from "../SVG/IconResolvedTicket";
import { TicketFiles } from "../../tools/ticketFiles";

const ChatPage = ({ ticketId, onClose }) => {
  const {
    userId,
    token,
    userName,
    userSurname,
    loading: userLoading,
  } = useAuth();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [ticketDetails, setTicketDetails] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [loadingTicket, setLoadingTicket] = useState(true);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [dataFetched, setDataFetched] = useState(false);
  const fetchData = async () => {
    setLoadingTicket(true);
    setLoadingMessages(true);
    try {
      const ticketData = await fetchTicketDetails(ticketId, token);
      setTicketDetails(ticketData);

      const messagesData = await fetchTicketMessages(ticketId, token);
      setMessages(messagesData || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingTicket(false);
      setLoadingMessages(false);
      setDataFetched(true);
    }
  };

  useEffect(() => {
    if (ticketId) {
      fetchData();
    }
  }, [ticketId]);

  useEffect(() => {
    if (dataFetched && ticketDetails) {
      switch (ticketDetails.status) {
        case "new":
          setCurrentStep(1);
          break;
        case "underreview":
          setCurrentStep(2);
          break;
        case "confirmed":
          setCurrentStep(3);
          break;
        case "solved":
          setCurrentStep(4);
          break;

        default:
          setCurrentStep(1);
      }
    }
  }, [dataFetched, ticketDetails]);

  const refreshData = async () => {
    await fetchData();
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    if (userLoading) return;

    const senderName = userName;
    const senderSurname = userSurname || "";

    const messageObject = {
      message: newMessage,
      sender: userId || "anonymous",
      sender_name: senderName,
      sender_surname: senderSurname,
    };

    setSendingMessage(true);
    try {
      const newMsg = await sendMessage(ticketId, messageObject, token);
      setMessages((prevMessages) => [...prevMessages, newMsg]);
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setSendingMessage(false);
    }
  };

  const handlePrint = () => {
    const printableContent = document.querySelector(".printable");

    if (!printableContent) {
      console.error("No element with class '.printable' found for printing.");
      return;
    }

    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write(
      "<style>@media print { .no-print { display: none; } }</style>"
    );
    printWindow.document.write("</head><body>");
    printWindow.document.write('<div class="no-print">');
    printWindow.document.write("</div>");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !sendingMessage && newMessage.trim() !== "") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="fixed inset-0 scroll-smooth bg-black bg-opacity-50 flex justify-center items-center z-50 animate-fadeIn">
      <div className="bg-white w-[90%] h-[90%] md:w-[80%] md:h-[90%] rounded-lg shadow-2xl relative flex flex-col transition-all duration-300 ease-in-out">
        <div className="absolute top-6 right-6 flex space-x-2">
          <button
            className="flex items-center space-x-2 text-black p-2 rounded  hover:bg-gray-200 transition-colors duration-300 active:bg-gray-300 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={refreshData}
            disabled={loadingTicket}
          >
            <IconRefresh className="w-6 h-6" />
            {/* {loading ? <span>Loading...</span> : <span>Refresh</span>} */}
          </button>
          <button
            onClick={handlePrint}
            disabled={loadingTicket}
            className="text-gray-500 hover:text-gray-700 p-2  hover:bg-gray-200  active:bg-gray-300 focus:outline-none transition-all duration-300 ease-in-out active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <IconPrinter className="w-8 h-8" />
          </button>
          <button
            onClick={onClose}
            disabled={loadingTicket}
            className="text-gray-500 hover:text-gray-700 p-2 focus:outline-none  hover:bg-gray-200  active:bg-gray-300 transition-all duration-300 ease-in-out active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <IconClose className="w-8 h-8" />
          </button>
        </div>

        {/* Content */}
        <div className="flex flex-col h-full">
          {/* Scrollable Content Area */}
          <div className="p-4 flex-grow overflow-y-auto printable">
            {/* Ticket Details */}
            {loadingTicket ? (
              <ProgressBar className="w-5 h-5" />
            ) : ticketDetails ? (
              <div className="bg-white shadow-lg rounded-lg p-6 mb-6 border border-gray-200">
                <h2 className="text-2xl font-bold text-gray-800 mb-2">
                  Ticket #{ticketId}: {ticketDetails.name || "Untitled Ticket"}
                </h2>
                <div className="border-t border-gray-200 pt-4 mt-4 grid grid-cols-2">
                  <div>
                    <div className="flex items-center mb-4">
                      <div className="w-16 h-16 rounded-full bg-gradient-to-r from-yellow-400 to-yellow-600 flex items-center justify-center text-white text-xl font-semibold mr-4">
                        {ticketDetails.sender.userName
                          ? `${ticketDetails.sender.userName[0]}${
                              ticketDetails.sender.userSurname
                                ? ticketDetails.sender.userSurname[0]
                                : ""
                            }`
                          : "U"}
                      </div>

                      <div className="flex-1">
                        <p className="text-lg font-medium text-gray-800">
                          {ticketDetails.sender.name || "Unknown"}{" "}
                          {ticketDetails.sender.surname || ""}
                        </p>
                        <p className="text-sm text-gray-500">
                          {ticketDetails.sender.department || "No department"}
                        </p>
                      </div>
                    </div>
                    <div>
                      <span className="">Prior: </span>
                      <span
                        className={`px-2 py-1 my-6 rounded-lg text-sm font-semibold ${
                          ticketDetails.priority === "High"
                            ? "bg-red-100 text-red-800"
                            : ticketDetails.priority === "Medium"
                            ? "bg-yellow-100 text-yellow-800"
                            : ticketDetails.priority === "Low"
                            ? "bg-green-100 text-green-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {ticketDetails.priority}
                      </span>
                    </div>

                    <div className="flex items-center mb-4">
                      <p className="text-sm text-gray-600 flex-1">
                        <span className="font-semibold text-gray-700">
                          Recipients:
                        </span>{" "}
                        {ticketDetails.recipients &&
                        ticketDetails.recipients.length > 0
                          ? ticketDetails.recipients.map((recipient, index) => (
                              <span key={recipient.id}>
                                {recipient.name || "Unknown"}{" "}
                                {recipient.surname || ""}{" "}
                                <span className="text-gray-400">
                                  ({recipient.department || "No department"})
                                </span>
                                {index < ticketDetails.recipients.length - 1 &&
                                  ", "}
                              </span>
                            ))
                          : "No recipients assigned"}
                      </p>
                    </div>

                    <div className="mt-4">
                      <h3 className="text-xl font-semibold text-gray-700 mb-2">
                        Description
                      </h3>
                      <p className="text-base text-gray-600 leading-relaxed">
                        {ticketDetails.description || "No description"}
                      </p>
                    </div>
                  </div>
                  <ol className="overflow-hidden space-y-4  ">
                    {/* Step 1 - Ticket Creation */}
                    <li
                      className={`relative flex-1 after:content-[''] after:w-0.5 after:h-full ${
                        currentStep >= 1
                          ? "after:bg-yellow-400"
                          : "after:bg-gray-200"
                      } after:inline-block after:absolute after:-bottom-11 after:left-4 lg:after:left-5`}
                    >
                      <div className="flex items-center font-medium w-full">
                        <span
                          className={`w-8 h-8 ${
                            currentStep >= 1
                              ? "bg-yellow-400 text-white"
                              : "bg-gray-50 text-gray-600"
                          } border-2 ${
                            currentStep >= 1
                              ? "border-transparent"
                              : "border-gray-200"
                          } rounded-full flex justify-center items-center mr-3 text-sm lg:w-10 lg:h-10`}
                        >
                          <IconAdd className="w-8 h-8 " />
                        </span>
                        <div className="block">
                          <h4
                            className={`text-lg ${
                              currentStep >= 1 ? "text-black" : "text-gray-900"
                            }`}
                          >
                            Ticket Created
                          </h4>
                          <span className="text-xs">
                            {formatDate(ticketDetails.creation_date) ||
                              "Unknown date"}
                          </span>
                        </div>
                      </div>
                    </li>

                    {/* Step 2 - Assigned to Manager */}
                    <li
                      className={`relative flex-1 after:content-[''] after:w-0.5 after:h-full ${
                        currentStep >= 2
                          ? "after:bg-yellow-400"
                          : "after:bg-gray-200"
                      } after:inline-block after:absolute after:-bottom-12 after:left-4 lg:after:left-5`}
                    >
                      <div className="flex items-center font-medium w-full">
                        <span
                          className={`w-8 h-8 ${
                            currentStep >= 2
                              ? "bg-yellow-400 text-white"
                              : "bg-gray-50 text-gray-600"
                          } border-2 ${
                            currentStep >= 2
                              ? "border-yellow-400"
                              : "border-gray-200"
                          } rounded-full flex justify-center items-center mr-3 text-sm lg:w-10 lg:h-10`}
                        >
                          <IconEye className="w-8 h-8" />
                        </span>
                        <div className="block">
                          <h4
                            className={`text-lg ${
                              currentStep >= 2 ? "text-black" : "text-gray-900"
                            }`}
                          >
                            Reviewed By Manager
                          </h4>
                          <span className="text-xs">
                            {ticketDetails.manager_open_date
                              ? formatDate(ticketDetails.manager_open_date)
                              : "Not seen"}
                          </span>
                        </div>
                      </div>
                    </li>

                    {/* Step 3B - Forwarded to User */}
                    {ticketDetails.recipients &&
                      ticketDetails.recipients.length > 0 && (
                        <li
                          className={`relative flex-1 ${
                            currentStep >= 2
                              ? "after:bg-yellow-400"
                              : "after:bg-gray-200"
                          } after:content-[''] after:absolute after:w-0.5 after:h-full after:left-4 lg:after:left-5 ${
                            currentStep >= 2
                              ? "after:border-yellow-400"
                              : "after:border-gray-200"
                          }`}
                        >
                          <div className="flex items-center font-medium w-full">
                            <span
                              className={`w-8 h-8 ${
                                currentStep >= 2
                                  ? "bg-yellow-400 text-white"
                                  : "bg-gray-50 text-gray-600"
                              } border-2 ${
                                currentStep >= 2
                                  ? "border-yellow-400"
                                  : "border-gray-200"
                              } rounded-full flex justify-center items-center mr-3 text-sm lg:w-10 lg:h-10`}
                            >
                              <IconForward className="w-6 h-6" />
                            </span>
                            <div className="block">
                              <h4
                                className={`text-lg ${
                                  currentStep >= 2
                                    ? "text-black"
                                    : "text-gray-900"
                                }`}
                              >
                                Forwarded to User
                              </h4>
                              <span className="text-xs">
                                {ticketDetails.ticket_forwarded_date
                                  ? formatDate(
                                      ticketDetails.ticket_forwarded_date
                                    )
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </li>
                      )}

                    {/* Step 3A - Resolved by Manager */}
                    <li
                      className={`relative flex-1 after:content-[''] after:w-0.5 after:h-full ${
                        currentStep >= 3
                          ? "after:bg-yellow-400"
                          : "after:bg-gray-200"
                      } after:inline-block after:absolute after:-bottom-12 after:left-4 lg:after:left-5`}
                    >
                      <div className="flex items-center font-medium w-full">
                        <span
                          className={`w-8 h-8 ${
                            currentStep >= 3
                              ? "bg-yellow-400 text-white"
                              : "bg-gray-50 text-gray-600"
                          } border-2 ${
                            currentStep >= 3
                              ? "border-yellow-400"
                              : "border-gray-200"
                          } rounded-full flex justify-center items-center mr-3 text-sm lg:w-10 lg:h-10`}
                        >
                          <IconCheckmarkDone className="w-8 h-8" />
                        </span>
                        <div className="block">
                          <h4
                            className={`text-lg ${
                              currentStep >= 3 ? "text-black" : "text-gray-900"
                            }`}
                          >
                            Resolved by Manager
                          </h4>
                          <span className="text-xs">
                            {ticketDetails.confirm_date
                              ? formatDate(ticketDetails.confirm_date)
                              : ""}
                          </span>
                        </div>
                      </div>
                    </li>

                    {/* Final Step - Ticket Closed */}
                    <li className="relative flex-1">
                      <div className="flex items-center font-medium w-full">
                        <span
                          className={`w-8 h-8 ${
                            currentStep === 4
                              ? "bg-yellow-400 text-white"
                              : "bg-gray-50 text-gray-600"
                          } border-2 ${
                            currentStep === 4
                              ? "border-gray-200"
                              : "border-gray-200"
                          } rounded-full flex justify-center items-center mr-3 text-sm lg:w-10 lg:h-10`}
                        >
                          <IconResolvedTicket className="w-5 h-5" />
                        </span>
                        <div className="block">
                          <h4
                            className={`text-lg ${
                              currentStep === 4 ? "text-black" : "text-gray-900"
                            }`}
                          >
                            Ticket Closed
                          </h4>
                          <span className="text-xs">
                            {ticketDetails.closing_date
                              ? formatDate(ticketDetails.closing_date)
                              : ""}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
                <TicketFiles ticketId={ticketId} />
              </div>
            ) : (
              <p className="text-gray-600">No ticket details available.</p>
            )}

            {/* Messages Section */}
            {loadingMessages ? (
              <ProgressBar className="w-5 h-5" />
            ) : (
              <div className="flex flex-col space-y-4 p-4">
                {Array.isArray(messages) && messages.length > 0 ? (
                  messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`flex ${
                        msg.sender === userId ? "flex-row-reverse" : "flex-row"
                      } items-start`}
                    >
                      <div
                        className={`flex-shrink-0 h-10 w-10 rounded-full flex items-center justify-center text-white font-bold ${
                          msg.sender === userId
                            ? "bg-gradient-to-r from-blue-500 to-blue-600"
                            : "bg-gradient-to-r from-indigo-500 to-indigo-600"
                        }`}
                      >
                        {msg.sender_name
                          ? `${msg.sender_name[0].toUpperCase()}`
                          : "U"}
                      </div>
                      <div
                        className={`ml-3 p-3 rounded-xl border shadow ${
                          msg.sender === userId ? "bg-blue-100" : "bg-white"
                        }`}
                      >
                        <p>{msg.message}</p>
                        <small>
                          {msg.sender_name || "Unknown"}{" "}
                          {msg.sender_surname || ""} -{" "}
                          {formatDate(msg.timestamp) || "Unknown time"}
                        </small>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-600">No messages yet.</p>
                )}
              </div>
            )}
          </div>

          <div className="p-4 bg-gray-100 border-t flex items-center">
            <input
              type="text"
              className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-100 focus:placeholder:opacity-100"
              placeholder="Type your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              disabled={sendingMessage}
              onKeyDown={handleKeyDown}
            />

            <button
              onClick={handleSendMessage}
              disabled={sendingMessage || newMessage.trim() === ""}
              className="ml-4 px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
            >
              <IconSend className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
