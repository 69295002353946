export const handleSaveTicket = async (
  ticketId,
  updatedData,
  token,
  serverIP,
  setTickets,
  closeEditModal
) => {
  try {
    const response = await fetch(`${serverIP}api/tickets/${ticketId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedData),
    });
    if (!response.ok) {
      throw new Error("Failed to update ticket");
    }
    const updatedTicket = await response.json();
    setTickets((tickets) =>
      tickets.map((ticket) => (ticket.id === ticketId ? updatedTicket : ticket))
    );
    closeEditModal();
  } catch (error) {
    console.error("Error updating ticket:", error);
    alert("Failed to update ticket");
  }
};

export const handleDelete = async (
  selectedTicket,
  token,
  serverIP,
  setTickets,
  setSelectedTicket
) => {
  if (!selectedTicket) {
    alert("Please select a ticket first");
    return;
  }
  if (window.confirm("Are you sure you want to delete this ticket?")) {
    try {
      const response = await fetch(`${serverIP}api/tickets/${selectedTicket}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setTickets((tickets) =>
          tickets.filter((ticket) => ticket.id !== selectedTicket)
        );
        setSelectedTicket(null);
      } else {
        alert("Failed to delete ticket");
      }
    } catch (error) {
      console.error("Error deleting ticket", error);
      alert("Failed to delete ticket");
    }
  }
};

export const handleResolve = async (
  selectedTicket,
  token,
  serverIP,
  setTickets,
  setSelectedTicket,
  refreshData
) => {
  if (!selectedTicket) {
    alert("Please select a ticket first");
    return;
  }
  try {
    const response = await fetch(
      `${serverIP}api/tickets/${selectedTicket}/resolve`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      const updatedTicket = await response.json();

      // Update the ticket list but keep the resolved ticket in the state with the updated "resolved" status
      setTickets((tickets) =>
        tickets.map((ticket) =>
          ticket.id === selectedTicket
            ? { ...ticket, status: "solved", ...updatedTicket }
            : ticket
        )
      );

      setSelectedTicket(null);
      refreshData(); // Call this if needed to reload other data
    } else {
      alert("Failed to resolve ticket");
    }
  } catch (error) {
    console.error("Error resolving ticket", error);
    alert("Failed to resolve ticket");
  }
};

export const handleEditTicket = (selectedTicket, setEditModalOpen) => {
  if (!selectedTicket) {
    alert("Please select a ticket first");
    return;
  }
  setEditModalOpen(true);
};
