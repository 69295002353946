import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { useAuth } from "../../auth";
import ChatPage from "../general/ChatPage";

import ImageModal from "../../Modals/ImageModal";

import EditTicketModal from "../../Modals/EditTicketModal";
import ProgressBar from "../../tools/ProgressBar";
import LoadingTicketList from "../../tools/LoadingTicketList";
import TicketsList from "../TicketsList";
import { RefreshButton } from "../../tools/RefreshButton";
import { fetchData } from "../../utils/fetchHelpers";
import { DeleteButton, EditButton, ResolveButton } from "../../utils/buttons";
import {
  handleDelete,
  handleEditTicket,
  handleResolve,
  handleSaveTicket,
} from "../../tools/ticketActions";
import { Sidebar } from "../Sidebar";
import { serverIP } from "../../api";

const UserOpenTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState("");
  const [chatPageOpen, setChatPageOpen] = useState(false);
  const [chatTicketId, setChatTicketId] = useState(null);
  const { token, userId, userDepartment } = useAuth();
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchTickets = async () => {
    const url = `${serverIP}api/userCreatedTickets?userId=${userId}`;
    const sortFunction = (data) => data;
    await fetchData(
      url,
      setTickets,
      setLoading,
      token,
      userDepartment,
      sortFunction
    );
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const refreshData = async () => {
    await fetchTickets();
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };
  const openImageModal = (imageSrc) => {
    setSelectedImageSrc(imageSrc);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
    setSelectedImageSrc("");
  };

  const openChatPage = (ticketId) => {
    setChatTicketId(ticketId);
    setChatPageOpen(true);
  };

  const closeChatPage = () => {
    setChatPageOpen(false);
    setChatTicketId(null);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-col md:flex-row">
        <Sidebar />
        <div className="flex-1 relative">
          <Navbar className="" />

          {loading ? <ProgressBar /> : <div className="h-1.5"></div>}
          <div className="p-4 sm:p-6 md:p-8">
            <h1 className="text-2xl sm:text-3xl font-semibold mb-6">
              Open Tickets
            </h1>
            <div className="bg-white shadow-md rounded-lg">
              <div className="p-4 flex flex-col sm:flex-row items-start sm:items-center justify-between">
                <div className="flex items-center justify-between px-4 py-2 bg-white shadow gap-4 rounded-md">
                  <h2 className="text-lg font-semibold text-gray-700 mb-4 sm:mb-0">
                    Tickets
                  </h2>

                  <RefreshButton onClick={refreshData} loading={loading} />
                </div>
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                  <EditButton
                    onClick={() =>
                      handleEditTicket(selectedTicket, setEditModalOpen)
                    }
                    disabled={loading}
                  />
                  <DeleteButton
                    onClick={() =>
                      handleDelete(
                        selectedTicket,
                        token,
                        serverIP,
                        setTickets,
                        setSelectedTicket
                      )
                    }
                    disabled={loading}
                  />
                  <ResolveButton
                    onClick={() =>
                      handleResolve(
                        selectedTicket,
                        token,
                        serverIP,
                        setTickets,
                        setSelectedTicket,
                        refreshData
                      )
                    }
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="relative ">
                {loading ? (
                  <LoadingTicketList />
                ) : (
                  <TicketsList
                    tickets={tickets}
                    selectedTicket={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                    handleRowDoubleClick={openChatPage}
                    openChatPage={openChatPage}
                    openImageModal={openImageModal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {imageModalOpen && (
        <ImageModal
          isOpen={imageModalOpen}
          onClose={closeImageModal}
          imageSrc={selectedImageSrc}
        />
      )}
      {chatPageOpen && (
        <ChatPage ticketId={chatTicketId} onClose={closeChatPage} />
      )}
      {editModalOpen && (
        <EditTicketModal
          isOpen={editModalOpen}
          onClose={closeEditModal}
          ticket={tickets.find((ticket) => ticket.id === selectedTicket)}
          onSave={(ticketId, updatedData) =>
            handleSaveTicket(
              ticketId,
              updatedData,
              token,
              serverIP,
              setTickets,
              closeEditModal
            )
          }
        />
      )}
    </div>
  );
};

export default UserOpenTickets;
