import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import ImageModal from "../../Modals/ImageModal";
import { useAuth } from "../../auth";
import ChatPage from "../general/ChatPage";
import EditTicketModal from "../../Modals/EditTicketModal";
import LoadingTicketList from "../../tools/LoadingTicketList";
import IconRefresh from "../SVG/IconRefresh";
import ProgressBar from "../../tools/ProgressBar";
import TicketsList from "../TicketsList";
import { DeleteButton, EditButton, ResolveButton } from "../../utils/buttons";
import {
  handleDelete,
  handleEditTicket,
  handleResolve,
  handleSaveTicket,
} from "../../tools/ticketActions";
import { fetchData } from "../../utils/fetchHelpers";
import { Sidebar } from "../Sidebar";
import { serverIP } from "../../api";

const AdminTickets = () => {
  const [tickets, setTickets] = useState([]);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState("");
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [chatPageOpen, setChatPageOpen] = useState(false);
  const [chatTicketId, setChatTicketId] = useState(null);
  const { token, userDepartment } = useAuth();
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const fetchTickets = async () => {
    const url = `${serverIP}api/tickets`;
    const sortFunction = (data) => data;
    await fetchData(
      url,
      setTickets,
      setLoading,
      token,
      userDepartment,
      sortFunction
    );
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const refreshData = async () => {
    await fetchTickets();
  };
  const openImageModal = (imageUrl) => {
    setSelectedImageSrc(imageUrl);
    setImageModalOpen(true);
  };
  const openChatPage = (ticketId) => {
    setChatTicketId(ticketId);
    setChatPageOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  return (
    <div className="flex min-h-screen">
      <Sidebar />

      <div className="flex-1 flex flex-col">
        <div className="flex-none bg-gray-900 text-white">
          <Navbar />
        </div>

        <div className="flex-none">
          {loading ? (
            <ProgressBar />
          ) : (
            <div className="h-1.5 bg-gray-100"></div>
          )}
        </div>

        <div className="flex-1 overflow-y-auto p-6 bg-gray-100">
          <div className="bg-white shadow-md rounded-lg">
            <div className="p-4 py-2 flex flex-col sm:flex-row items-start sm:items-center justify-between">
              <div className="flex items-center justify-between px-4 py-2 bg-white shadow gap-4 ">
                <h2 className="text-lg font-semibold text-gray-700 mb-4 sm:mb-0">
                  Tickets
                </h2>
                <button
                  className="flex items-center space-x-2 text-black p-2 rounded bg-gray-100 hover:bg-gray-200 transition-colors duration-300 active:bg-gray-300 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={refreshData}
                  disabled={loading}
                >
                  <IconRefresh className="w-6 h-6" />
                </button>
              </div>
              <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                <EditButton
                  onClick={() =>
                    handleEditTicket(selectedTicket, setEditModalOpen)
                  }
                  disabled={loading}
                />
                <DeleteButton
                  onClick={() =>
                    handleDelete(
                      selectedTicket,
                      token,
                      serverIP,
                      setTickets,
                      setSelectedTicket
                    )
                  }
                  disabled={loading}
                />
                <ResolveButton
                  onClick={() =>
                    handleResolve(
                      selectedTicket,
                      token,
                      serverIP,
                      setTickets,
                      setSelectedTicket
                    )
                  }
                  disabled={loading}
                />
              </div>
            </div>

            <div className="relative ">
              {loading ? (
                <LoadingTicketList />
              ) : (
                <TicketsList
                  tickets={tickets}
                  selectedTicket={selectedTicket}
                  setSelectedTicket={setSelectedTicket}
                  handleRowDoubleClick={openChatPage}
                  openChatPage={openChatPage}
                  openImageModal={openImageModal}
                  role="admin"
                />
              )}
            </div>
          </div>
        </div>

        {imageModalOpen && (
          <ImageModal
            isOpen={imageModalOpen}
            onClose={() => setImageModalOpen(false)}
            imageSrc={selectedImageSrc}
          />
        )}
        {chatPageOpen && (
          <ChatPage
            ticketId={chatTicketId}
            onClose={() => setChatPageOpen(false)}
          />
        )}
        {editModalOpen && (
          <EditTicketModal
            isOpen={editModalOpen}
            onClose={closeEditModal}
            ticket={tickets.find((ticket) => ticket.id === selectedTicket)}
            onSave={(ticketId, updatedData) =>
              handleSaveTicket(
                ticketId,
                updatedData,
                token,
                serverIP,
                setTickets,
                closeEditModal
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default AdminTickets;
