import React from "react";
import Modal from "./Modal";

const AddUserModal = ({
  userAddPart,
  setUserAddPart,
  handleAddUser,
  newUser,
  setNewUser,
  departments,
}) => {
  // Helper function to check if any required fields are empty
  const isFormValid = () => {
    return (
      newUser.firstName.trim() !== "" &&
      newUser.lastName.trim() !== "" &&
      newUser.role.trim() !== "" &&
      newUser.departmentId !== ""
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title="Add User"
      onClose={() => setUserAddPart(false)}
      show={userAddPart}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (isFormValid()) {
            handleAddUser();
          }
        }}
      >
        <div>
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={newUser.firstName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={newUser.lastName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={newUser.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="role">Role</label>
          <input
            type="text"
            id="role"
            name="role"
            value={newUser.role}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="departmentId">Department</label>
          <select
            id="departmentId"
            name="departmentId"
            value={newUser.departmentId}
            onChange={handleChange}
            required
          >
            <option value="">Select a department</option>
            {departments.map((department) => (
              <option key={department.id} value={department.id}>
                {department.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <button type="submit" disabled={!isFormValid()}>
            Add User
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddUserModal;
