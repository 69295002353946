import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IconLogout from "./SVG/IconLogout";

export default function Navbar({ fixed }) {
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [showLogoutModal, setShowLogoutModal] = useState(false); // New state for modal
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    setToken(null);
    navigate("/");
  };

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    setShowLogoutModal(false);
    logout();
  };

  const cancelLogout = () => {
    setShowLogoutModal(false);
  };

  return (
    <>
      <nav className="bg-gray-900  w-100 px-8 md:px-auto sticky  top-0 z-40">
        <div className="md:h-16 h-28 mx-auto md:px-4 container flex items-center justify-between flex-wrap md:flex-nowrap">
          <div className="text-indigo-500 md:order-1">
            <img
              src="https://www.sunvalleycyprus.com/uploads/images/sun-valley-cyprus-footerlogo-6627.png"
              className="w-32"
              alt="Logo"
            />
          </div>

          <div className="order-2 md:order-3 flex items-center space-x-4">
            <button
              className="flex items-center gap-2 px-4 py-2 text-white bg-red-500 hover:bg-red-600 font-bold rounded-lg transition duration-200"
              onClick={handleLogout}
            >
              <IconLogout className="w-6 h-6" />
            </button>
          </div>
        </div>
      </nav>

      {showLogoutModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white w-full max-w-sm mx-4 p-6 rounded-lg shadow-xl relative">
            <h2 className="text-xl font-semibold text-gray-800 text-center mb-6">
              Are you sure ?
            </h2>
            <div className="flex justify-between">
              <button
                className="px-4 py-2 w-full mr-2 bg-gray-300 hover:bg-gray-400 text-gray-700 font-medium rounded-md transition duration-200 shadow-sm"
                onClick={cancelLogout}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 w-full ml-2 bg-red-600 hover:bg-red-700 text-white font-medium rounded-md transition duration-200 shadow-sm active:bg-red-800 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
                onClick={confirmLogout}
              >
                <IconLogout className="w-5 h-5" />
                <span>Log out</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
