import React, { useState, useEffect } from "react";
import { useAuth } from "../../auth";

import IconDelete from "../SVG/IconDelete";
import IconAddDepartment from "../SVG/IconAddDepartment";

import IconClose from "../SVG/IconClose";
import IconAdd from "../SVG/IconAdd";
import {
  fetchDepartments,
  createDepartment,
  deleteDepartment,
  fetchSubjects,
  createSubject,
  deleteSubject,
} from "../../api";

const AdminCategoriesPage = ({ closeDepartmentManager }) => {
  const [departmentName, setDepartmentName] = useState("");
  const [departmentDescription, setDepartmentDescription] = useState("");
  const [departments, setDepartments] = useState([]);
  const [subjectName, setSubjectName] = useState("");
  const [subjects, setSubjects] = useState([]);
  const { token } = useAuth();

  // Fetch departments
  useEffect(() => {
    const fetchAllDepartments = async () => {
      try {
        const data = await fetchDepartments(token);
        setDepartments(data);
      } catch (error) {
        alert("Failed to fetch departments");
      }
    };

    fetchAllDepartments();
  }, [token]);

  // Fetch subjects
  useEffect(() => {
    const fetchAllSubjects = async () => {
      try {
        const data = await fetchSubjects(token);
        setSubjects(data);
      } catch (error) {
        alert("Failed to fetch subjects");
      }
    };

    fetchAllSubjects();
  }, [token]);

  // Create department
  const handleCreateDepartment = async () => {
    try {
      const newDepartment = await createDepartment(
        departmentName,
        departmentDescription,
        token
      );
      setDepartments([...departments, newDepartment]);
      setDepartmentName("");
      setDepartmentDescription("");
    } catch (error) {
      alert("Failed to create department");
    }
  };

  // Delete department
  const handleDeleteDepartment = async (id) => {
    try {
      await deleteDepartment(id, token);
      setDepartments(departments.filter((dep) => dep.id !== id));
    } catch (error) {
      alert("Failed to delete department");
    }
  };

  // Create subject
  const handleCreateSubject = async () => {
    try {
      const newSubject = await createSubject(subjectName, token);
      setSubjects([...subjects, newSubject]);
      setSubjectName("");
    } catch (error) {
      alert("Failed to create subject");
    }
  };

  // Delete subject
  const handleDeleteSubject = async (id) => {
    try {
      await deleteSubject(id, token);
      setSubjects(subjects.filter((sub) => sub.id !== id));
    } catch (error) {
      alert("Failed to delete subject");
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 md:p-8 rounded-lg shadow-xl w-11/12 max-w-4xl relative overflow-hidden">
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-800 transition duration-300"
          onClick={closeDepartmentManager}
        >
          <IconClose className="w-8 h-8" />
        </button>
        <h2 className="text-3xl font-extrabold mb-6 text-gray-900 text-center">
          Manage Departments & Ticket Subjects
        </h2>

        <div className="max-h-[70vh] overflow-y-auto">
          {" "}
          {/* Added max height and overflow */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Department Manager Section */}
            <div className="flex flex-col">
              <h3 className="text-2xl font-bold mb-4 text-gray-900">
                Departments
              </h3>
              <div className="relative mb-6">
                <input
                  placeholder=" "
                  type="text"
                  name="departmentName"
                  id="departmentName"
                  value={departmentName}
                  onChange={(e) =>
                    setDepartmentName(e.target.value.toLocaleUpperCase())
                  }
                  maxLength={60}
                  className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-none disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                />
                <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Department Name
                </label>
              </div>

              {/* Department Description Textarea */}
              <div className="relative mb-4">
                <textarea
                  placeholder=" "
                  value={departmentDescription}
                  onChange={(e) => setDepartmentDescription(e.target.value)}
                  className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-none disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                  rows="1"
                ></textarea>
                <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Department Description
                </label>
              </div>
              <div className="mb-2">
                <button
                  onClick={handleCreateDepartment}
                  className="bg-yellow-400 text-white px-4 py-2 font-bold rounded-lg shadow-md hover:bg-yellow-500 transition duration-300"
                >
                  <IconAddDepartment className="w-8 h-8" />
                </button>
              </div>

              <div className="max-h-60 overflow-y-auto border border-gray-200 rounded-lg">
                <ul className="list-disc pl-4">
                  {departments.map((department) => (
                    <li
                      key={department.id}
                      className="flex justify-between items-center  p-2 py-1 border-b border-gray-200"
                    >
                      <span className="text-gray-800 font-medium">
                        {department.name} - {department.description}
                      </span>
                      <button
                        onClick={() => handleDeleteDepartment(department.id)}
                        className="bg-red-600 text-white p-1 rounded hover:bg-red-700 transition duration-300"
                      >
                        <IconDelete className="w-6 h-6" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Subject Manager Section */}
            <div className="flex flex-col">
              <h3 className="text-2xl font-bold mb-4 text-gray-900">
                Ticket Subjects
              </h3>
              <div className="mb-6">
                <div className="relative">
                  <input
                    type="text"
                    value={subjectName}
                    onChange={(e) => setSubjectName(e.target.value)}
                    placeholder=" " // Placeholder to trigger label animation
                    className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-none disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                  />
                  <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-gray-500">
                    Enter subject name
                  </label>
                </div>
                <button
                  onClick={handleCreateSubject}
                  className="bg-yellow-400 mt-2 text-white px-4 py-2 font-bold rounded-lg shadow-md hover:bg-yellow-500 transition duration-300"
                >
                  <IconAdd className="inline-block mr-2" />
                  Add Subject
                </button>
              </div>

              <div className="max-h-60 overflow-y-auto border border-gray-200 rounded-lg">
                <ul className="list-disc pl-5">
                  {subjects.map((subject) => (
                    <li
                      key={subject.id}
                      className="flex justify-between items-center  p-2 py-1 border-b border-gray-200"
                    >
                      <span className="text-gray-800 font-medium">
                        {subject.subject_name}
                      </span>
                      <button
                        onClick={() => handleDeleteSubject(subject.id)}
                        className="bg-red-600 text-white p-1 rounded hover:bg-red-700 transition duration-300"
                      >
                        <IconDelete className="w-6 h-6" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCategoriesPage;
