import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { useAuth } from "../auth";
import {
  IconDiagram,
  IconOpenTickets,
  IconPerson,
  IconReport,
  IconUnits,
  IconUsers,
} from "../SVG/svg";
import IconAdd from "./SVG/IconAdd";
import UserAddTicket from "./user/UserAddTicket";
import IconInbox from "./SVG/IconInbox";
import IconUpload from "./SVG/IconUpload";

import Iconburger from "./SVG/IconBurger";
import IconResolvedTicket from "./SVG/IconResolvedTicket";
import { serverIP } from "../api";
import AdminCategoriesPage from "./admin/AdminCategoriesPage";

const breakpoint = 768;

export const Sidebar = () => {
  const { userName, userId, token, userRole, userDepartment } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [counts, setCounts] = useState({ created: 0, inbox: 0 });
  const [addTicketOpen, setAddTicketOpen] = useState(false);
  const location = useLocation();
  const [isDepartmentManagerOpen, setIsDepartmentManagerOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch(
          `${serverIP}api/tickets/counts/${userId}/${userRole}/${userDepartment}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if response is OK
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setCounts(data);
      } catch (error) {
        console.error("Error fetching open ticket count", error);
      }
    };

    fetchCounts();
  }, [setCounts, token, userId, userRole]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= breakpoint) {
        setIsSidebarOpen(true); // Always open on larger screens
      } else {
        setIsSidebarOpen(false); // Close on smaller screens
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call on initial load

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <button
        onClick={toggleSidebar}
        className="p-4 text-white bg-gray-800 fixed top-4 left-4 z-50 rounded-full shadow-lg md:hidden"
        aria-label="Toggle Sidebar"
      >
        <Iconburger />
      </button>

      {/* Sidebar */}
      <div
        className={`fixed left-0 top-0 w-64 bg-slate-900 text-white min-h-full flex flex-col transition-transform transform duration-300 z-50 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0`}
      >
        <div className="py-6 flex-1">
          <h2 className="text-xl mb-8 px-4 font-bold flex items-center space-x-2">
            <IconPerson className="w-10 h-10" />
            <span>{userName}</span>
          </h2>
          {userRole === "user" && (
            <>
              <ul className="mt-6">
                <li className="flex items-center">
                  <button
                    onClick={() => {
                      setAddTicketOpen(true);
                      setIsSidebarOpen(false); // Close sidebar when adding ticket
                    }}
                    className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                      location.pathname === "/user/addticket"
                        ? "bg-gray-700 text-yellow-300"
                        : "text-white"
                    }`}
                    aria-label="Add Ticket"
                  >
                    <IconAdd className="w-8 h-8" />
                    <span>Add Ticket</span>
                  </button>
                </li>
                <li className="flex items-center">
                  <Link
                    to="/user/inbox"
                    onClick={() => setIsSidebarOpen(false)}
                    className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                      location.pathname === "/user/inbox"
                        ? "bg-gray-700 text-yellow-300"
                        : "text-white"
                    }`}
                    aria-label="Inbox Tickets"
                  >
                    <IconInbox className="w-8 h-8" />
                    <span>Inbox Tickets</span>
                    <span className="ml-2 bg-red-500 text-white text-xs font-semibold rounded-full px-2 py-1">
                      {counts.inbox}
                    </span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link
                    to="/user/opentickets"
                    onClick={() => setIsSidebarOpen(false)}
                    className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                      location.pathname === "/user/opentickets"
                        ? "bg-gray-700 text-yellow-300"
                        : "text-white"
                    }`}
                    aria-label="Open Tickets"
                  >
                    <IconUpload className="w-8 h-8" />
                    <span>Open Tickets</span>

                    <span className="ml-2 bg-gray-500 text-white text-xs font-semibold rounded-full px-2 py-1">
                      {counts.created}
                    </span>
                  </Link>
                </li>
              </ul>
            </>
          )}
          {userRole === "manager" && (
            <>
              <ul className="mt-6">
                <li className="flex items-center">
                  <button
                    onClick={() => {
                      setAddTicketOpen(true);
                    }}
                    className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                      location.pathname === "/user/addticket"
                        ? "bg-gray-700 text-yellow-300"
                        : "text-white"
                    }`}
                    aria-label="Add Ticket"
                  >
                    <IconAdd className="w-8 h-8" />
                    <span>Create a Ticket</span>
                  </button>
                </li>
                <li className="flex items-center">
                  <Link
                    to="/manager"
                    onClick={() => setIsSidebarOpen(false)}
                    className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                      location.pathname === "/manager/inbox"
                        ? "bg-gray-700 text-yellow-300"
                        : "text-white"
                    }`}
                    aria-label="Inbox Tickets"
                  >
                    <IconInbox className="w-8 h-8" />
                    <span>Inbox Tickets</span>

                    <span className="ml-2 bg-red-500 text-white text-xs font-semibold rounded-full px-2 py-1">
                      {counts.inbox}
                    </span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link
                    to="/manager/created"
                    onClick={() => setIsSidebarOpen(false)}
                    className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                      location.pathname === "/manager/created"
                        ? "bg-gray-700 text-yellow-300"
                        : "text-white"
                    }`}
                    aria-label="Open Tickets"
                  >
                    <IconUpload className="w-8 h-8" />
                    <span>Sent Tickets</span>

                    <span className="ml-2 bg-gray-500 text-white text-xs font-semibold rounded-full px-2 py-1">
                      {counts.created}
                    </span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link
                    to="/manager/resolved"
                    onClick={() => setIsSidebarOpen(false)} // Close sidebar
                    className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                      location.pathname === "/manager/resolved"
                        ? "bg-gray-700 text-yellow-300"
                        : "text-white"
                    }`}
                    aria-label="Open Tickets"
                  >
                    <IconResolvedTicket className="w-8 h-8" />
                    <span>Resolved Tickets</span>
                  </Link>
                </li>
                <li className="flex items-center">
                  <Link
                    to="/manager/users"
                    onClick={() => setIsSidebarOpen(false)} // Close sidebar
                    className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                      location.pathname === "/manager/users"
                        ? "bg-gray-700 text-yellow-300"
                        : "text-white"
                    }`}
                    aria-label="Open Tickets"
                  >
                    <IconUsers className="w-8 h-8" />
                    <span>Manage Users</span>
                  </Link>
                </li>
              </ul>
            </>
          )}
          {userRole === "admin" && (
            <>
              <li className="flex items-center">
                <Link
                  to="/admin/tickets"
                  onClick={() => setIsSidebarOpen(false)} // Close sidebar
                  className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                    location.pathname === "/admin/tickets"
                      ? "bg-gray-700 text-yellow-300"
                      : "text-white"
                  }`}
                  aria-label="Inbox Tickets"
                >
                  <IconOpenTickets className="w-8 h-8" />
                  <span>Tickets</span>
                  <span className="ml-2 bg-gray-500 text-white text-xs font-semibold rounded-full px-2 py-1">
                    {counts.inbox}
                  </span>
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  to="/admin/users"
                  onClick={() => setIsSidebarOpen(false)}
                  className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                    location.pathname === "/admin/users"
                      ? "bg-gray-700 text-yellow-300"
                      : "text-white"
                  }`}
                >
                  <IconUsers className="w-8 h-8" />
                  <span>Manage Users</span>
                </Link>
              </li>

              <li className="flex items-center">
                <button
                  onClick={() => setIsDepartmentManagerOpen(true)}
                  className="p-4 hover:bg-gray-700 w-full flex items-center space-x-2"
                >
                  <IconUnits className="w-8 h-8" />
                  <span>Manage Props</span>
                </button>
              </li>
              <li className="flex items-center">
                <Link
                  to="/admin/overview"
                  className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                    location.pathname === "/admin/overview"
                      ? "bg-gray-700 text-yellow-300"
                      : "text-white"
                  }`}
                >
                  <IconDiagram className="w-8 h-8" />
                  <span>Overview</span>
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  to="/admin/reports"
                  className={`p-4 hover:bg-gray-700 w-full flex items-center space-x-2 transition-colors duration-200 ${
                    location.pathname === "/admin/reports"
                      ? "bg-gray-700 text-yellow-300"
                      : "text-white"
                  }`}
                >
                  <IconReport className="w-8 h-8" />
                  <span>Reports</span>
                </Link>
              </li>
            </>
          )}
        </div>
        {/* <div className="p-4 text-center font-mono text-xs text-gray-400">
          <span>Version 1.5</span>
          <br />
          <span>Powered By Sardor</span>
        </div> */}
      </div>

      {/* Sidebar Overlay for mobile */}
      {isSidebarOpen && window.innerWidth < breakpoint && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-80 z-40 transition-opacity duration-300"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Main Content Area */}
      <div
        className={`transition-all duration-300 ${
          isSidebarOpen ? "ml-64" : "ml-0"
        } md:ml-64`}
      >
        {addTicketOpen && (
          <UserAddTicket onClose={() => setAddTicketOpen(false)} />
        )}
        {isDepartmentManagerOpen && (
          <AdminCategoriesPage
            closeDepartmentManager={() => setIsDepartmentManagerOpen(false)}
          />
        )}
      </div>
    </>
  );
};
