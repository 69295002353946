import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import IconEdit from "../SVG/IconEdit";
import IconDelete from "../SVG/IconDelete";
import IconClose from "../SVG/IconClose";
import { useAuth } from "../../auth";
import { formatDate, deleteUser, addUserAdmin } from "../../tools/Tools";
import EditUserModal from "../../utils/editUserModalAdmin";
import { Sidebar } from "../Sidebar";
import Modal from "../../Modals/Modal";
import IconAddUser from "../SVG/IconAddUser";
import { serverIP } from "../../api";
import AddUserModal from "../../Modals/AddUserModal";
import ProgressBar from "../../tools/ProgressBar";

const AdminUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [userAddPart, setUserAddPart] = useState(false);
  const { token, userDepartment } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    username: "",
    name: "",
    surname: "",
    role: "",
    department: "",
    email: "",
    password: "",
  });
  const [editUser, setEditUser] = useState({
    username: "",
    name: "",
    surname: "",
    role: "",
    department: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    fetchUsers();
    fetchDepartments();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${serverIP}api/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-user-role": "admin",
          "x-user-department": userDepartment,
        },
      });
      const data = await response.json();

      if (Array.isArray(data)) {
        setUsers(data);
      } else {
        console.error("Unexpected data format:", data);
        setUsers([]);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await fetch(`${serverIP}api/departments`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setDepartments(data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };
  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentUsers =
    filteredUsers.length > 0
      ? filteredUsers.slice(indexOfFirstTicket, indexOfLastTicket)
      : users.slice(indexOfFirstTicket, indexOfLastTicket);

  const totalPages = Math.ceil(users.length / ticketsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handleAddUser = (e) => {
    addUserAdmin(
      e,
      serverIP,
      token,
      newUser,
      fetchUsers,
      setNewUser,
      setUserAddPart
    );
  };

  const handleEditUser = (e) => {
    editUser(
      e,
      serverIP,
      token,
      editingUser,
      editUser,
      fetchUsers,
      setEditingUser,
      setEditUser
    );
  };

  const handleDeleteUser = (userId) => {
    deleteUser(userId, serverIP, token, fetchUsers);
  };
  const toggleAddUser = () => {
    setUserAddPart(!userAddPart);
  };

  const startEditingUser = (user) => {
    setEditUser({
      username: user.username,
      name: user.name,
      surname: user.surname,
      role: user.role,
      department: user.department,
      email: user.email,
      password: "",
    });
    setEditingUser(user);
  };
  const handleSearch = (event, field) => {
    const value = event.target.value.toLowerCase();
    if (value === "") {
      setFilteredUsers([]); // Reset search if input is cleared
    } else {
      const filtered = users.filter((user) =>
        user[field]?.toLowerCase().includes(value)
      );
      setFilteredUsers(filtered);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 bg-gray-100">
        <Navbar />
        {loading ? <ProgressBar /> : <div className="h-1.5"></div>}
        <div className="p-6 py-1">
          <div className="p-6">
            <h1 className="text-3xl font-bold mb-2">User Management</h1>
            <button
              className={`my-2 p-2 rounded-md flex items-center gap-2 transition-colors duration-300 ${
                userAddPart
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-yellow-400 hover:bg-yellow-500"
              }`}
              onClick={toggleAddUser}
            >
              {userAddPart ? (
                <>
                  <IconClose className="w-5 h-5 text-white" />
                  <span className="text-white font-semibold">Close</span>
                </>
              ) : (
                <>
                  <IconAddUser className="w-5 h-5 text-white" />
                  <span className="text-white font-semibold">Add User</span>
                </>
              )}
            </button>

            <div className="relative  shadow-md sm:rounded-lg">
              <table className="min-w-full text-sm text-left text-gray-500 shadow-lg rounded-lg overflow-hidden">
                <thead className="text-xs text-gray-700 uppercase bg-gradient-to-r from-gray-100 to-gray-200">
                  <tr>
                    <th className="px-6 py-1">Username</th>
                    <th className="px-6 py-1">Name</th>
                    <th className="px-6 py-1">Surname</th>
                    <th className="px-6 py-1">Role</th>
                    <th className="px-6 py-1">Department</th>
                    <th className="px-6 py-1">Email</th>
                    <th className="px-6 py-1">Created At</th>
                    <th className="px-6 py-1 text-center">Actions</th>
                  </tr>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-1">
                      <input
                        type="text"
                        placeholder="Search Username"
                        onChange={(e) => handleSearch(e, "username")}
                        className="w-full p-1 border border-gray-300 rounded"
                      />
                    </th>
                    <th className="px-6 py-1">
                      <input
                        type="text"
                        placeholder="Search Name"
                        onChange={(e) => handleSearch(e, "name")}
                        className="w-full p-1 border border-gray-300 rounded"
                      />
                    </th>
                    <th className="px-6 py-1">
                      <input
                        type="text"
                        placeholder="Search Surname"
                        onChange={(e) => handleSearch(e, "surname")}
                        className="w-full p-1 border border-gray-300 rounded"
                      />
                    </th>
                    <th className="px-6 py-1">
                      <input
                        type="text"
                        placeholder="Search Role"
                        onChange={(e) => handleSearch(e, "role")}
                        className="w-full p-1 border border-gray-300 rounded"
                      />
                    </th>
                    <th className="px-6 py-1">
                      <input
                        type="text"
                        placeholder="Search Department"
                        onChange={(e) => handleSearch(e, "department")}
                        className="w-full p-1 border border-gray-300 rounded"
                      />
                    </th>
                    <th className="px-6 py-1">
                      <input
                        type="text"
                        placeholder="Search Email"
                        onChange={(e) => handleSearch(e, "email")}
                        className="w-full p-1 border border-gray-300 rounded"
                      />
                    </th>
                    {/* <th className="px-6 py-1">
                      <input
                        type="text"
                        placeholder="Search Date"
                        onChange={(e) => handleSearch(e, "created_at")}
                        className="w-full p-1 border border-gray-300 rounded"
                      />
                    </th> */}
                    <th className="px-6 py-1 text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user) => (
                    <tr
                      key={user.id}
                      className="bg-white border-b hover:bg-gray-100 transition duration-300 ease-in-out"
                    >
                      <td className="px-6 py-1">{user.username}</td>
                      <td className="px-6 py-1">{user.name}</td>
                      <td className="px-6 py-1">{user.surname}</td>
                      <td className="px-6 py-1">{user.role}</td>
                      <td className="px-6 py-1">{user.department}</td>
                      <td className="px-6 py-1">{user.email}</td>
                      <td className="px-6 py-1">
                        {formatDate(user.registered_date)}
                      </td>
                      <td className="px-6 py-1 flex justify-center gap-3">
                        <button
                          onClick={() => startEditingUser(user)}
                          className="px-2 py-1 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition duration-300"
                        >
                          <IconEdit className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteUser(user.id)}
                          className="px-2 py-1 rounded-full bg-red-500 text-white hover:bg-red-600 transition duration-300"
                        >
                          <IconDelete className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex mx-16 justify-between pb-4 items-center mt-4">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition-colors"
                >
                  Previous
                </button>

                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition-colors"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          {editingUser && (
            <EditUserModal
              editingUser={editingUser}
              setEditingUser={setEditingUser}
              editUser={editUser}
              setEditUser={setEditUser}
              handleEditUser={handleEditUser}
              departments={departments}
            />
          )}{" "}
          <AddUserModal
            userAddPart={userAddPart}
            setUserAddPart={setUserAddPart}
            handleAddUser={handleAddUser}
            newUser={newUser}
            setNewUser={setNewUser}
            departments={departments}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminUsersPage;
