import React, { useState, useEffect } from "react";
import { useAuth } from "../auth";
import { useDropzone } from "react-dropzone";
import IconClose from "../components/SVG/IconClose";
import { serverIP } from "../api";

const EditTicketModal = ({ isOpen, onClose, ticket, onSave }) => {
  const { userId, token } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    sender_id: userId,
  });
  const [files, setFiles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  useEffect(() => {
    if (ticket) {
      setFormData({
        name: ticket.name,
        description: ticket.description,
        sender_id: ticket.sender_id,
      });
      setFiles(ticket.images || []);
    }
  }, [ticket]);

  useEffect(() => {
    fetchDepartments();
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      fetchUsersByDepartment(selectedDepartment);
    }
  }, [selectedDepartment]);

  const fetchDepartments = async () => {
    try {
      const response = await fetch(`${serverIP}api/departments`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setDepartments(data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const fetchUsersByDepartment = async (department) => {
    try {
      const response = await fetch(
        `${serverIP}api/departments/users?department=${department}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        // setUsers(data);
      } else {
        console.error("Expected array for users, got:", data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onDrop = (acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: true,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedTicketData = { ...formData };

    const formDataWithImage = new FormData();
    Object.keys(updatedTicketData).forEach((key) =>
      formDataWithImage.append(key, updatedTicketData[key])
    );
    files.forEach((file) => formDataWithImage.append("images", file));

    try {
      const response = await fetch(`${serverIP}api/editTicket/${ticket.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formDataWithImage,
      });

      if (response.ok) {
        alert("Ticket updated successfully");
        setFormData({
          name: "",
          description: "",
          sender_id: userId,
        });
        setFiles([]);
        setSelectedDepartment("");
        onSave();
        onClose();
      } else {
        const errorData = await response.json();
        alert(`Failed to update ticket: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error updating ticket", error);
      alert("Failed to update ticket");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative max-w-lg w-full bg-white text-black p-8 rounded-lg shadow-lg">
        <IconClose
          onClick={onClose}
          className="absolute top-2 right-2 w-8 h-8 text-gray-500 hover:text-gray-700 focus:outline-none"
        />
        <h2 className="text-3xl font-semibold text-gray-800 mb-6">
          Edit Ticket
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Description
            </label>
            <textarea
              name="description"
              id="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              required
            ></textarea>
          </div>
          <div className="mb-4">
            <label
              htmlFor="department"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Department
            </label>
            <select
              id="department"
              name="department"
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              required
            >
              <option value="" disabled>
                Select Department
              </option>
              {departments.map((dept) => (
                <option key={dept.id} value={dept.name}>
                  {dept.name}
                </option>
              ))}
            </select>
          </div>
          <div
            {...getRootProps({
              className:
                "dropzone p-4 border-2 border-dashed border-gray-300 rounded-lg text-center cursor-pointer mb-4 hover:bg-gray-50 transition",
            })}
          >
            <input {...getInputProps()} />
            <p className="text-gray-500">
              Drag & drop some files here, or click to select files
            </p>
          </div>
          <div className="flex flex-wrap gap-2 mb-6">
            {files.map((file) => (
              <div key={file.name} className="w-24 h-24 relative">
                <img
                  src={file.preview}
                  alt={file.name}
                  className="w-full h-full object-cover rounded-lg shadow-md cursor-pointer"
                  onClick={() => window.open(file.preview, "_blank")}
                />
              </div>
            ))}
          </div>
          <button
            type="submit"
            className="w-full py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditTicketModal;
