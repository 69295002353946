import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { useAuth } from "../../auth";
import ChatPage from "../general/ChatPage";

import ImageModal from "../../Modals/ImageModal";

import ProgressBar from "../../tools/ProgressBar";
import LoadingTicketList from "../../tools/LoadingTicketList";
import TicketsList from "../TicketsList";
import { RefreshButton } from "../../tools/RefreshButton";
import { fetchData } from "../../utils/fetchHelpers";
import { Sidebar } from "../Sidebar";
import { serverIP } from "../../api";

const UserInbox = () => {
  const [tickets, setTickets] = useState([]);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState("");
  const [chatPageOpen, setChatPageOpen] = useState(false);
  const [chatTicketId, setChatTicketId] = useState(null);
  const { token, userId, userDepartment } = useAuth();
  const [loading, setLoading] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const fetchTickets = async () => {
    const url = `${serverIP}api/user-tickets?userId=${userId}`;
    const sortFunction = (data) => data;

    await fetchData(
      url,
      setTickets,
      setLoading,
      token,
      userDepartment,
      sortFunction
    );
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const refreshData = async () => {
    await fetchTickets();
  };

  const openImageModal = (imageSrc) => {
    setSelectedImageSrc(imageSrc);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
    setSelectedImageSrc("");
  };

  const openChatPage = (ticketId) => {
    fetch(`${serverIP}api/tickets/${selectedTicket}/hasOpened`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Assuming you're using a token for auth
      },
      body: JSON.stringify({ userId: userId }), // Pass the current user's ID
    });
    setChatTicketId(ticketId);
    setChatPageOpen(true);
  };

  const closeChatPage = () => {
    setChatPageOpen(false);
    setChatTicketId(null);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-col md:flex-row">
        <Sidebar className="md:w-1/4" />
        <div className="flex-1 relative">
          <Navbar />
          {loading ? <ProgressBar /> : <div className="h-1.5"></div>}
          <div className="p-4 sm:p-6 md:p-8">
            <h1 className="text-2xl sm:text-3xl font-semibold mb-6">
              User Inbox
            </h1>
            <div className="bg-white shadow-md ">
              <div className="flex items-center justify-between px-4 py-2 bg-white shadow gap-4 ">
                <h2 className="text-lg font-semibold text-gray-700 mb-4 sm:mb-0">
                  Tickets
                </h2>
                <RefreshButton onClick={refreshData} loading={loading} />
              </div>
              <div className="relative ">
                {loading ? (
                  <LoadingTicketList />
                ) : (
                  <TicketsList
                    tickets={tickets}
                    selectedTicket={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                    handleRowDoubleClick={openChatPage}
                    openChatPage={openChatPage}
                    openImageModal={openImageModal}
                    role="user"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {imageModalOpen && (
        <ImageModal
          isOpen={imageModalOpen}
          onClose={closeImageModal}
          imageSrc={selectedImageSrc}
        />
      )}
      {chatPageOpen && (
        <ChatPage ticketId={chatTicketId} onClose={closeChatPage} />
      )}
    </div>
  );
};
export default UserInbox;
