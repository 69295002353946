import React from "react";
import ReactDOM from "react-dom";
import IconAddUser from "../components/SVG/IconAddUser";

const Modal = ({ title, onClose, onSubmit, children }) => {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 max-w-lg">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-2xl font-bold">{title}</h2>
          <button
            className="text-gray-600 hover:text-gray-900 text-xl"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className="p-4">{children}</div>
        <div className="flex justify-end p-4 border-t">
          <div className="relative group">
            <button
              className="bg-yellow-400 text-white px-4 py-2 rounded-md hover:bg-yellow-500"
              onClick={onSubmit}
            >
              <IconAddUser className="w-8 h-8" />
            </button>
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:flex bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-nowrap z-10">
              Add User
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
