// icon:forward | Entypo http://entypo.com/ | Daniel Bruce
import * as React from "react";

function IconForward(props) {
  return (
    <svg
      viewBox="0 0 900 1000"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M540 598c-140 0-250.667 15-332 45S57.333 738.667 0 840c2.667-13.333 7-31 13-53s22.667-61 50-117 59.333-105.333 96-148 88.667-81.667 156-117 142.333-53 225-53V160l360 322-360 334V598" />
    </svg>
  );
}

export default IconForward;
