import React from "react";
import { IconRefresh } from "../SVG/svg";

export const RefreshButton = ({ onClick, loading }) => {
  return (
    <button
      className="flex items-center space-x-2 text-black p-2 rounded bg-gray-100 hover:bg-gray-200 transition-colors duration-300 active:bg-gray-300 active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
      onClick={onClick}
      disabled={loading}
    >
      <IconRefresh className="w-6 h-6" />
    </button>
  );
};
