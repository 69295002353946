import { IconDelete, IconDone, IconEdit } from "../SVG/svg";

export const ResolveButton = ({ onClick, disabled }) => (
  <button
    className="bg-green-400 hover:bg-green-600 text-white py-2 px-4 rounded active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
    onClick={onClick}
    disabled={disabled}
  >
    <IconDone className="h-5 w-5" />
  </button>
);
export const DeleteButton = ({ onClick, disabled }) => (
  <button
    className="bg-red-400 hover:bg-red-600 text-white py-2 px-4 rounded active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
    onClick={onClick}
    disabled={disabled}
  >
    <IconDelete className="h-5 w-5" />
  </button>
);
export const EditButton = ({ onClick, disabled }) => (
  <button
    className="bg-gray-400 hover:bg-gray-600 text-white py-2 px-4 rounded active:scale-95 disabled:opacity-50 disabled:cursor-not-allowed"
    onClick={onClick}
    disabled={disabled}
  >
    <IconEdit className="h-5 w-5" />
  </button>
);
