import React, { useEffect, useRef, useState } from "react";

import Navbar from "../Navbar";
import IconPerson from "../SVG/IconPerson";
import IconUnits from "../SVG/IconUnits";
import IconUsers from "../SVG/IconUsers";
import { fetchCountsTree, fetchTreeData } from "../../api";
import { createTree } from "../../tools/createTree";
import { useAuth } from "../../auth";
import { Sidebar } from "../Sidebar";
import ProgressBar from "../../tools/ProgressBar";

const AdminOverView = () => {
  const [data, setData] = useState(null);
  const svgRef = useRef();
  const [departmentCount, setDepartmentCount] = useState(0);
  const [managerCount, setManagerCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const treeResult = await fetchTreeData(token);
        setData(treeResult);

        const countsResult = await fetchCountsTree(token);
        setDepartmentCount(countsResult.departmentCount);
        setManagerCount(countsResult.managerCount);
        setUserCount(countsResult.userCount);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    createTree(svgRef, data);
  }, [data]);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 bg-gray-100">
        <Navbar />
        {loading ? <ProgressBar /> : <div className="h-1.5"></div>}
        <div className="p-4">
          <div className="flex gap-4 p-4 bg-gray-200 rounded-lg shadow-md">
            <div className="bg-white p-4 border border-gray-300 rounded-lg shadow-md flex-1 text-center">
              <IconUnits className="w-8 h-8 text-gray-700 mx-auto mb-2" />
              <h3 className="text-lg font-semibold text-gray-700 mb-2">
                Departments
              </h3>
              <p className="text-3xl font-bold text-gray-800">
                {departmentCount}
              </p>
            </div>
            <div className="bg-white p-4 border border-gray-300 rounded-lg shadow-md flex-1 text-center">
              <IconUsers className="w-8 h-8 text-gray-700 mx-auto mb-2" />

              <h3 className="text-lg font-semibold text-gray-700 mb-2">
                Managers
              </h3>
              <p className="text-3xl font-bold text-gray-800">{managerCount}</p>
            </div>
            <div className="bg-white p-4 border border-gray-300 rounded-lg shadow-md flex-1 text-center">
              <IconPerson className="w-8 h-8 text-gray-700 mx-auto mb-2" />
              <h3 className="text-lg font-semibold text-gray-700 mb-2">
                Users
              </h3>
              <p className="text-3xl font-bold text-gray-800">{userCount}</p>
            </div>
          </div>

          <div className="flex justify-center items-center mt-10">
            <svg ref={svgRef}></svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOverView;
