// icon:upload | Entypo http://entypo.com/ | Daniel Bruce
import * as React from "react";

function IconUpload(props) {
  return (
    <svg
      viewBox="0 0 1000 1000"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M499.75 74l260 244h-164v256h-190V318h-166l260-244m468 578c12 6.667 21 17.333 27 32 6 14.667 7 28 3 40l-28 154c-2.667 13.333-10 24.333-22 33-12 8.667-25.333 13-40 13h-816c-14.667 0-28-4.333-40-13s-19.333-19.667-22-33l-28-154c-6.667-32 4-56 32-72l158-108h98l-170 130h178c5.333 0 9.333 2.667 12 8l40 110h300l40-110c5.333-5.333 9.333-8 12-8h178l-170-130h98l160 108" />
    </svg>
  );
}

export default IconUpload;
