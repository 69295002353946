import React, { useState, useEffect, useCallback } from "react";
import { formatDate } from "../tools/Tools";

import IconChat from "./SVG/IconChat";
import { sortTickets } from "../tools/sortTickets";
import { fetchSubjects } from "../api";
import { useAuth } from "../auth";

const TicketsList = ({
  tickets = [],
  selectedTicket,
  setSelectedTicket,
  handleRowDoubleClick,
  openChatPage,
  openImageModal,
  ticketsPerPage = 10,
  role,
}) => {
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const { token } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTickets, setCurrentTickets] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [filters, setFilters] = useState({
    id: "",
    priority: "",
    name: "",
    description: "",
    sender_name: "",
    creation_date: "",
    subject_id: "",
  });

  const sortedTickets = sortTickets(
    tickets,
    sortConfig.key,
    sortConfig.direction
  );

  useEffect(() => {
    const fetchAllSubjects = async () => {
      try {
        const data = await fetchSubjects(token);
        setSubjects(data);
      } catch (error) {
        alert("Failed to fetch subjects");
      }
    };

    fetchAllSubjects();
  }, [token]);
  const filteredTickets = sortedTickets.filter((ticket) => {
    return (
      (filters.id === "" || ticket.id.toString().includes(filters.id)) &&
      (filters.priority === "" ||
        ticket.priority
          .toLowerCase()
          .includes(filters.priority.toLowerCase())) &&
      (filters.name === "" ||
        ticket.name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (filters.description === "" ||
        ticket.description
          .toLowerCase()
          .includes(filters.description.toLowerCase())) &&
      (filters.sender_name === "" ||
        ticket.sender_name
          .toLowerCase()
          .includes(filters.sender_name.toLowerCase())) &&
      (filters.creation_date === "" ||
        formatDate(ticket.creation_date).includes(filters.creation_date)) &&
      (filters.subject_id === "" || ticket.subject === filters.subject_id)
    );
  });

  const totalPages = Math.ceil(filteredTickets.length / ticketsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handleSort = useCallback(
    (key) => {
      let direction = "asc";
      if (sortConfig.key === key && sortConfig.direction === "asc") {
        direction = "desc";
      }
      setSortConfig({ key, direction });
    },
    [sortConfig]
  );

  const handleFilterChange = (e, key) => {
    setFilters((prev) => ({ ...prev, [key]: e.target.value }));
    setCurrentPage(1);
  };

  useEffect(() => {
    const indexOfLastTicket = currentPage * ticketsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;

    const newTickets = filteredTickets.slice(
      indexOfFirstTicket,
      indexOfLastTicket
    );

    if (JSON.stringify(newTickets) !== JSON.stringify(currentTickets)) {
      setCurrentTickets(newTickets);
    }
  }, [filteredTickets, currentPage, ticketsPerPage]);
  return (
    <div className="bg-white shadow-md overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full text-left text-sm text-gray-500">
          <thead className="text-xs uppercase bg-gray-200 text-gray-600">
            <tr>
              <th
                className="px-4 py-3 cursor-pointer"
                onClick={() => handleSort("id")}
              >
                ID{""}
                {/* {sortConfig.key === "id" &&
                  (sortConfig.direction === "asc" ? "▲" : "▼")} */}
                <input
                  type="text"
                  value={filters.id}
                  onChange={(e) => handleFilterChange(e, "id")}
                  placeholder="Search ID"
                  className="w-full mt-1 p-1 border rounded"
                />
              </th>
              <th
                className="px-4 py-3 cursor-pointer"
                onClick={() => handleSort("priority")}
              >
                Priority{" "}
                <select
                  value={filters.priority}
                  onChange={(e) => handleFilterChange(e, "priority")}
                  className="w-full mt-1  px-0 py-1 border rounded"
                >
                  <option value="">All</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </th>
              <th
                className="px-4 py-3 cursor-pointer"
                onClick={() => handleSort("name")}
              >
                Title{" "}
                {/* {sortConfig.key === "name" &&
                  (sortConfig.direction === "asc" ? "▲" : "▼")} */}
                <input
                  type="text"
                  value={filters.name}
                  onChange={(e) => handleFilterChange(e, "name")}
                  placeholder="Search Title"
                  className="w-full mt-1 p-1 border rounded"
                />
              </th>
              <th
                className="px-4 py-3 cursor-pointer"
                onClick={() => handleSort("description")}
              >
                Description{" "}
                {/* {sortConfig.key === "description" &&
                  (sortConfig.direction === "asc" ? "▲" : "▼")} */}
                <input
                  type="text"
                  value={filters.description}
                  onChange={(e) => handleFilterChange(e, "description")}
                  placeholder="Search Description"
                  className="w-full mt-1 p-1 border rounded"
                />
              </th>
              <th
                className="px-4 py-3 cursor-pointer"
                onClick={() => handleSort("sender_name")}
              >
                Sender{" "}
                {/* {sortConfig.key === "sender_name" &&
                  (sortConfig.direction === "asc" ? "▲" : "▼")} */}
                <input
                  type="text"
                  value={filters.sender_name}
                  onChange={(e) => handleFilterChange(e, "sender_name")}
                  placeholder="Search Sender"
                  className="w-full mt-1 p-1 border rounded"
                />
              </th>
              <th className="px-4 py-3">
                Subject
                <select
                  value={filters.subject_id}
                  onChange={(e) => handleFilterChange(e, "subject_id")}
                  className="w-full mt-1 px-0 py-1 border rounded"
                >
                  <option value="">All</option> {/* Default option */}
                  {subjects.map((subject) => (
                    <option
                      key={subject.subject_name}
                      value={subject.subject_name}
                    >
                      {subject.subject_name}
                    </option>
                  ))}
                </select>
              </th>
              <th
                className="px-4 py-3 cursor-pointer"
                onClick={() => handleSort("creation_date")}
              >
                Created At{" "}
                {/* {sortConfig.key === "creation_date" &&
                  (sortConfig.direction === "asc" ? "▲" : "▼")} */}
                <input
                  type="text"
                  value={filters.creation_date}
                  onChange={(e) => handleFilterChange(e, "creation_date")}
                  placeholder="Search Date"
                  className="w-full mt-1 p-1 border rounded"
                />
              </th>
              <th className="px-4 py-3">Status</th>

              <th className="px-4 py-3">Actions</th>
            </tr>
          </thead>

          <tbody>
            {currentTickets.map((ticket, index) => (
              <tr
                key={ticket.id}
                className={`cursor-pointer border-b ${
                  selectedTicket === ticket.id
                    ? "bg-yellow-300"
                    : index % 2 === 0
                    ? "bg-white"
                    : "bg-gray-200"
                } hover:bg-yellow-300 transition-colors ${
                  (role === "manager" && ticket.status === "new") ||
                  (role === "user" && !ticket.has_opened)
                    ? "font-bold text-black"
                    : ""
                }`}
                onClick={() => setSelectedTicket(ticket.id)}
                onDoubleClick={() => handleRowDoubleClick(ticket.id)}
              >
                <td className="px-4 py-2 w-32 text-center">{ticket.id}</td>
                <td
                  className={`px-4 py-2 w-20 ${
                    ticket.priority === "High"
                      ? "bg-red-200 text-red-800"
                      : ticket.priority === "Medium"
                      ? "bg-yellow-200 text-yellow-800"
                      : ticket.priority === "Low"
                      ? "bg-green-200 text-green-800"
                      : "bg-gray-100 text-gray-800"
                  } text-center`}
                >
                  {ticket.priority}
                </td>
                <td className="px-4 py-2">
                  {ticket.name.length > 15
                    ? `${ticket.name.substring(0, 15)}...`
                    : ticket.name}
                </td>
                <td className="px-4 py-2">
                  {ticket.description.length > 30
                    ? `${ticket.description.substring(0, 30)}...`
                    : ticket.description}
                </td>
                <td className="px-4 py-2">
                  <div className="flex items-center">
                    <span className="mr-2">{ticket.sender_name}</span>
                    <span
                      className={
                        `inline-flex items-center px-2 py-1 text-sm font-medium rounded-full 
      ${
        ticket.sender_department === "Sales"
          ? "bg-blue-500 text-white"
          : ticket.sender_department === "Support"
          ? "bg-green-500 text-white"
          : ticket.sender_department === "Development"
          ? "bg-orange-500 text-white"
          : ticket.sender_department === "Marketing"
          ? "bg-purple-500 text-white"
          : ticket.sender_department === "HR"
          ? "bg-pink-500 text-white"
          : ticket.sender_department === "Finance"
          ? "bg-teal-500 text-white"
          : ticket.sender_department === "IT"
          ? "bg-indigo-500 text-white"
          : ticket.sender_department === "Administration"
          ? "bg-yellow-500 text-black"
          : "bg-gray-300 text-black"
      }` // Default color
                      }
                    >
                      {ticket.sender_department}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-1">
                  {ticket.subject === "No Subject" ? "---" : ticket.subject}
                </td>
                <td className="px-4 py-1">
                  {formatDate(ticket.creation_date)}
                </td>
                <td className="px-6 py-1 text-center">
                  <span
                    className={`inline-block px-3  text-sm font-medium rounded-full ${
                      ticket.status === "new"
                        ? "bg-blue-200 text-blue-800"
                        : ticket.status === "underreview"
                        ? "bg-yellow-200 text-yellow-800"
                        : ticket.status === "confirmed"
                        ? "bg-purple-200 text-purple-800"
                        : ticket.status === "solved"
                        ? "bg-green-200 text-green-800"
                        : "bg-gray-200 text-gray-800"
                    }`}
                  >
                    {ticket.status.charAt(0).toUpperCase() +
                      ticket.status.slice(1).replace(/([A-Z])/g, " $1")}
                  </span>
                </td>

                <td className="px-4  text-center">
                  <button
                    className="bg-yellow-400 hover:bg-yellow-500 text-white py-1 px-2 rounded-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      openChatPage(ticket.id);
                    }}
                  >
                    <IconChat className="w-5 h-5" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col sm:flex-row justify-between items-center my-4 px-4">
        <div className="text-gray-700 mb-2 sm:mb-0">
          Total tickets: {filteredTickets.length}
        </div>

        <div className="flex items-center">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="bg-gray-500 text-white py-2 px-4 hover:bg-gray-600 transition-colors disabled:opacity-50"
          >
            Previous
          </button>

          <span className="text-gray-700 border py-2 px-4">
            {currentPage}/{totalPages}
          </span>

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="bg-gray-500 text-white py-2 px-4 hover:bg-gray-600 transition-colors disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default TicketsList;
