import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { useAuth } from "../../auth";
import ChatPage from "../general/ChatPage";

import ImageModal from "../../Modals/ImageModal";

import ProgressBar from "../../tools/ProgressBar";
import IconRefresh from "../SVG/IconRefresh";
import LoadingTicketList from "../../tools/LoadingTicketList";
import TicketsList from "../TicketsList";
import { RefreshButton } from "../../tools/RefreshButton";
import { fetchData } from "../../utils/fetchHelpers";
import { Sidebar } from "../Sidebar";
import { serverIP } from "../../api";

const ManagerResolvedTickets = () => {
  const [tickets, setTickets] = useState([]);
  const { token, userDepartment } = useAuth();
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState("");
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [chatPageOpen, setChatPageOpen] = useState(false);
  const [chatTicketId, setChatTicketId] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchTickets = async () => {
    const url = `${serverIP}api/manager/resolved?department=${userDepartment}`;
    const sortFunction = (data) => data;

    await fetchData(
      url,
      setTickets,
      setLoading,
      token,
      userDepartment,
      sortFunction
    );
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const refreshData = async () => {
    await fetchTickets();
  };
  const openImageModal = (imageSrc) => {
    setSelectedImageSrc(imageSrc);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
    setSelectedImageSrc("");
  };

  const openChatPage = (ticketId) => {
    setChatTicketId(ticketId);
    setChatPageOpen(true);
  };

  const closeChatPage = () => {
    setChatPageOpen(false);
    setChatTicketId(null);
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <div className="flex flex-col md:flex-row">
        <Sidebar />
        <div className="flex-1 relative">
          <Navbar />
          {loading ? <ProgressBar /> : <div className="h-1.5"></div>}

          <div className="p-4 sm:p-6 md:p-8">
            <h1 className="text-2xl sm:text-3xl font-semibold mb-6">
              Resolved Tickets
            </h1>

            <div className="bg-white shadow-md rounded-lg">
              <div className="p-4 flex flex-col sm:flex-row items-start sm:items-center justify-between">
                <h2 className="text-lg font-semibold text-gray-700 mb-4 sm:mb-0">
                  Tickets
                </h2>

                <RefreshButton onClick={refreshData} loading={loading} />
              </div>
              <div className="relative  ">
                {loading ? (
                  <LoadingTicketList />
                ) : (
                  <TicketsList
                    tickets={tickets}
                    selectedTicket={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                    handleRowDoubleClick={openChatPage}
                    openChatPage={openChatPage}
                    openImageModal={openImageModal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {imageModalOpen && (
        <ImageModal
          isOpen={imageModalOpen}
          onClose={closeImageModal}
          imageSrc={selectedImageSrc}
        />
      )}
      {chatPageOpen && (
        <ChatPage ticketId={chatTicketId} onClose={closeChatPage} />
      )}
    </div>
  );
};

export default ManagerResolvedTickets;
