import React, { useState } from "react";
import Modal from "../Modals/Modal";

const EditUserModal = ({
  editingUser,
  setEditingUser,
  editUser,
  setEditUser,
  handleEditUser,
  departments,
}) => {
  const [password, setPassword] = useState(""); // State for new password

  return (
    editingUser && (
      <Modal
        title="Edit User"
        onClose={() => setEditingUser(null)}
        onSubmit={(e) => {
          e.preventDefault();
          handleEditUser();
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleEditUser();
          }}
          className="flex flex-col gap-4"
        >
          <div className="flex flex-col md:flex-row gap-4">
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                placeholder="Username"
                type="text"
                name="username"
                id="username"
                value={editUser.username}
                onChange={(e) =>
                  setEditUser({ ...editUser, username: e.target.value })
                }
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                required
              />
              <label
                htmlFor="username"
                className="absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-gray-500 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
              >
                Username
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                placeholder="Email"
                type="email"
                name="email"
                id="email"
                value={editUser.email}
                onChange={(e) =>
                  setEditUser({ ...editUser, email: e.target.value })
                }
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                required
              />
              <label
                htmlFor="email"
                className="absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-gray-500 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
              >
                Email
              </label>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                placeholder="Name"
                type="text"
                name="name"
                id="name"
                value={editUser.name}
                onChange={(e) =>
                  setEditUser({ ...editUser, name: e.target.value })
                }
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                required
              />
              <label
                htmlFor="name"
                className="absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-gray-500 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
              >
                Name
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <input
                placeholder="Surname"
                type="text"
                name="surname"
                id="surname"
                value={editUser.surname}
                onChange={(e) =>
                  setEditUser({ ...editUser, surname: e.target.value })
                }
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                required
              />
              <label
                htmlFor="surname"
                className="absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-gray-500 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
              >
                Surname
              </label>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-4">
            <div className="relative h-11 w-full min-w-[200px]">
              <select
                name="role"
                id="role"
                value={editUser.role}
                onChange={(e) =>
                  setEditUser({ ...editUser, role: e.target.value })
                }
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                required
                aria-label="Role"
              >
                <option value="" disabled>
                  Select Role
                </option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
                <option value="manager">Manager</option>
              </select>
              <label
                htmlFor="role"
                className="absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-gray-500 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
              >
                Role
              </label>
            </div>
            <div className="relative h-11 w-full min-w-[200px]">
              <select
                name="department"
                id="department"
                value={editUser.department}
                onChange={(e) =>
                  setEditUser({ ...editUser, department: e.target.value })
                }
                className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                required
                aria-label="Department"
              >
                <option value="" disabled>
                  Select Department
                </option>
                {departments.map((department) => (
                  <option key={department.id} value={department.name}>
                    {department.name}
                  </option>
                ))}
              </select>
              <label
                htmlFor="department"
                className="absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-gray-500 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
              >
                Department
              </label>
            </div>
          </div>

          {/* Password Field */}
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              placeholder="New Password"
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
              required
            />
            <label
              htmlFor="password"
              className="absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-gray-500 transition-all peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
            >
              New Password
            </label>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="rounded-md bg-yellow-400 py-2 px-4 text-white"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    )
  );
};

export default EditUserModal;
