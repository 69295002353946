// fetchHelpers.js
export const fetchData = async (
  url,
  setData,
  setLoading,
  token,
  userDepartment,
  sortFunction = (data) => data // Default to no sorting
) => {
  // Verify setLoading is a function
  if (typeof setLoading !== "function") {
    console.error("setLoading is not a function", setLoading);
    return;
  }

  setLoading(true);
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-User-Department": userDepartment,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setData(sortFunction(data));
    } else {
      const errorData = await response.text();
      console.error("Failed to fetch data:", response.status, errorData);
      alert("Failed to fetch data");
    }
  } catch (error) {
    console.error("Error fetching data", error);
    alert("Error fetching data");
  } finally {
    setLoading(false);
  }
};
