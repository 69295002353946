import React, { useState, useEffect } from "react";

import Navbar from "../../components/Navbar";
import ImageModal from "../../Modals/ImageModal";
import ChatPage from "../../components/general/ChatPage";
import ForwardModal from "../../Modals/ForwardModal";
import ProgressBar from "../../tools/ProgressBar";
import LoadingTicketList from "../../tools/LoadingTicketList";
import TicketsList from "../../components/TicketsList";
import { RefreshButton } from "../../tools/RefreshButton";
import { fetchData } from "../../utils/fetchHelpers";
import { sortTickets } from "../../tools/sortTickets";
import { handleForwardToUsers, serverIP } from "../../api";
import Alert from "../../tools/alert";
import { Sidebar } from "../../components/Sidebar";
import { IconDone, IconForward } from "../../SVG/svg";
import { useAuth } from "../../auth";

const ManagerInbox = () => {
  const [tickets, setTickets] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState("");
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [chatPageOpen, setChatPageOpen] = useState(false);
  const [chatTicketId, setChatTicketId] = useState(null);
  const { token, userDepartment } = useAuth();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });

  useEffect(() => {
    const fetchDataAndUsers = async () => {
      try {
        // Fetch Tickets
        await fetchData(
          `${serverIP}api/manager/tickets`,
          setTickets,
          setLoading,
          token,
          userDepartment,
          sortTickets
        );

        // Fetch Users (merged fetchUsers logic)
        setLoading(true);
        const response = await fetch(`${serverIP}api/users`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-user-role": "manager",
            "x-user-department": userDepartment,
          },
        });
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error("Error fetching data", error);
        alert("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndUsers();
  }, [token, userDepartment, serverIP]);

  const refreshData = async () => {
    try {
      await fetchData(
        `${serverIP}api/manager/tickets`,
        setTickets,
        setLoading,
        token,
        userDepartment,
        sortTickets
      );
    } catch (error) {
      console.error("Error refreshing data:", error);
      alert("Error refreshing data");
    }
  };

  const forwardToUsers = async (ticketId, userIds) => {
    await handleForwardToUsers(ticketId, userIds, token, setLoading, alert);
  };

  const openModal = () => {
    if (!selectedTicket) {
      alert("Please select a ticket first");
      return;
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUsers([]);
  };

  const openImageModal = (imageSrc) => {
    setSelectedImageSrc(imageSrc);
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
    setSelectedImageSrc("");
  };

  const openChatPage = async (ticketId) => {
    try {
      const response = await fetch(
        `${serverIP}api/tickets/${ticketId}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ status: "underreview" }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setChatTicketId(ticketId);
        setChatPageOpen(true);
      } else {
        console.error("Failed to update status:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
    const handleStatusChangeLocally = (ticketId, newStatus) => {
      setTickets((prevTickets) =>
        prevTickets.map((ticket) =>
          ticket.id === ticketId ? { ...ticket, status: newStatus } : ticket
        )
      );
    };

    handleStatusChangeLocally(ticketId, "underreview");
    setChatTicketId(ticketId);
    setChatPageOpen(true);
  };

  const closeChatPage = () => {
    setChatPageOpen(false);
    setChatTicketId(null);
  };

  const handleConfirm = async () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to confirm this ticket?"
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const response = await fetch(
        `${serverIP}api/tickets/${selectedTicket}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ status: "confirmed" }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        setAlert({
          message: "Ticket confirmed successfully.",
          type: "success",
        });
      } else {
        setAlert({
          message: `Error confirming ticket: ${data.error}`,
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error confirming ticket:", error);
      setAlert({ message: "Failed to confirm the ticket.", type: "error" });
    }
  };
  return (
    <div className="flex flex-col min-h-screen bg-gray-300">
      <div className="flex flex-col md:flex-row relative">
        <Sidebar className="md:w-1/4" />

        <div className="flex-1 relative md:ml-1/4">
          <div className="sticky top-0 z-20 bg-white shadow-md">
            <Navbar />
          </div>

          {loading ? <ProgressBar /> : <div className="h-1.5"></div>}

          <div className="p-4 sm:p-2 md:p-4">
            <div className="bg-white shadow-md rounded-lg overflow-hidden overflow-x-auto overflow-y-auto h-[calc(100vh-7rem)] border border-gray-400  ">
              <div className="px-4 py-2 flex flex-col sm:flex-row items-start sm:items-center justify-between bg-white  shadow-sm">
                <div className="flex items-center justify-between px-4 py-2 gap-4 ">
                  <h2 className="text-lg font-semibold text-gray-700">
                    Tickets
                  </h2>
                  <RefreshButton onClick={refreshData} loading={loading} />
                </div>

                <div className="mt-4 sm:mt-0 flex flex-wrap gap-2 items-center justify-end">
                  <button
                    className="active:scale-95 disabled:opacity-50 bg-slate-800 hover:bg-yellow-600 text-white py-2 px-4 rounded"
                    onClick={openModal}
                    disabled={loading}
                  >
                    <IconForward className="h-5 w-5" />
                  </button>
                  <button
                    className="active:scale-95 disabled:opacity-50 bg-slate-800 hover:bg-yellow-600 text-white py-2 px-4 rounded"
                    onClick={handleConfirm}
                    disabled={loading}
                  >
                    <IconDone className="h-5 w-5" />
                  </button>
                </div>
              </div>

              <div className="relative  ">
                {loading ? (
                  <LoadingTicketList />
                ) : (
                  <TicketsList
                    tickets={tickets}
                    selectedTicket={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                    handleRowDoubleClick={openChatPage}
                    openChatPage={openChatPage}
                    openImageModal={openImageModal}
                    role="manager"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <ForwardModal
            isOpen={isModalOpen}
            onClose={closeModal}
            users={users}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            handleForwardToUsers={forwardToUsers}
            ticketId={selectedTicket}
          />
        )}
        {imageModalOpen && (
          <ImageModal
            isOpen={imageModalOpen}
            onClose={closeImageModal}
            imageSrc={selectedImageSrc}
          />
        )}
        {chatPageOpen && (
          <ChatPage ticketId={chatTicketId} onClose={closeChatPage} />
        )}
        {alert.message && (
          <Alert
            message={alert.message}
            type={alert.type}
            onClose={() => setAlert({ message: "", type: "" })}
          />
        )}
      </div>
    </div>
  );
};

export default ManagerInbox;
