import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import IconEdit from "../SVG/IconEdit";
import IconDelete from "../SVG/IconDelete";
import IconAddUser from "../SVG/IconAddUser";
import IconClose from "../SVG/IconClose";
import { useAuth } from "../../auth";
import { formatDate } from "../../tools/Tools";
import ProgressBar from "../../tools/ProgressBar";
import LoadingTicketList from "../../tools/LoadingTicketList";
import { Sidebar } from "../Sidebar";
import Modal from "../../Modals/Modal";
import { serverIP } from "../../api";

const ManagerUsersPage = () => {
  const [users, setUsers] = useState([]);

  const [editingUser, setEditingUser] = useState(null);
  const [userAddPart, setUserAddPart] = useState(false);
  const { token, userDepartment } = useAuth();

  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    name: "",
    surname: "",
    role: "user",
    department: userDepartment,
    email: "",
    password: "",
  });
  const [editUser, setEditUser] = useState({
    username: "",
    name: "",
    surname: "",
    role: "user",
    department: userDepartment,
    email: "",
    password: "",
  });

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${serverIP}api/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-user-role": "manager",
          "x-user-department": userDepartment,
        },
      });
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
    // fetchDepartments();
  }, []);
  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(serverIP + "api/addUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newUser),
      });
      if (response.ok) {
        fetchUsers();
        setNewUser({
          username: "",
          name: "",
          surname: "",
          role: "",
          department: "",
          email: "",
          password: "",
        });
        setUserAddPart(false); // Close modal after adding user
      } else {
        console.error("Error adding user:", await response.json());
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(serverIP + `api/users/${editingUser.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(editUser),
      });
      if (response.ok) {
        fetchUsers();
        setEditingUser(null);
        setEditUser({
          username: "",
          name: "",
          surname: "",
          role: "",
          department: "",
          email: "",
          password: "",
        });
      } else {
        console.error("Error editing user:", await response.json());
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const response = await fetch(serverIP + `api/users/${userId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        fetchUsers();
      } else {
        console.error("Error deleting user:", await response.json());
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const toggleAddUser = () => {
    setUserAddPart(!userAddPart);
  };

  const startEditingUser = (user) => {
    setEditUser({
      username: user.username,
      name: user.name,
      surname: user.surname,
      role: user.role,
      department: user.department,
      email: user.email,
      password: "", // Do not pre-fill password
    });
    setEditingUser(user);
  };

  // const handleSelectUser = (user) => {
  //   setSelectedUser(user);
  //   setEditUser({
  //     username: user.username,
  //     email: user.email,
  //     name: user.name,
  //     surname: user.surname,
  //     role: user.role,
  //     department: user.department,
  //     password: "", // Clear password for security
  //   });
  //   setEditingUser(user);
  // };
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 bg-gray-100 relative">
        <Navbar />
        {loading ? <ProgressBar /> : <div className="h-1.5"></div>}
        <div className="p-6">
          <div className="p-6">
            <h1 className="text-3xl font-bold mb-4">
              User Management | {userDepartment} Department
            </h1>
            <button
              className={`my-2 p-2 mb-8 rounded-md flex items-center gap-2 transition-colors duration-300 ${
                userAddPart
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-yellow-400 hover:bg-yellow-500"
              }`}
              onClick={toggleAddUser}
              disabled={loading}
            >
              {userAddPart ? (
                <>
                  <IconClose className="w-5 h-5 text-white" />
                  <span className="text-white font-semibold">Close</span>
                </>
              ) : (
                <>
                  <IconAddUser className="w-5 h-5 text-white" />
                  <span className="text-white font-semibold">Add User</span>
                </>
              )}
            </button>
            {userAddPart && (
              <Modal
                title="Add New User"
                onClose={() => setUserAddPart(false)}
                onSubmit={handleAddUser}
              >
                <form onSubmit={handleAddUser} className="flex flex-col gap-4">
                  <p className="font-bold">
                    Add New User to {userDepartment} Department
                  </p>

                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="relative h-11 w-full min-w-[200px]">
                      <input
                        placeholder=""
                        type="text"
                        name="username"
                        id="username"
                        value={newUser.username}
                        onChange={(e) =>
                          setNewUser({ ...newUser, username: e.target.value })
                        }
                        className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                        required
                      />
                      <label
                        htmlFor="username"
                        className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
                      >
                        Username
                      </label>
                    </div>

                    <div className="relative h-11 w-full min-w-[200px]">
                      <input
                        placeholder=""
                        type="email"
                        name="email"
                        id="email"
                        value={newUser.email}
                        onChange={(e) =>
                          setNewUser({ ...newUser, email: e.target.value })
                        }
                        className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                        required
                      />
                      <label
                        htmlFor="email"
                        className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
                      >
                        Email
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="relative h-11 w-full min-w-[200px]">
                      <input
                        placeholder=""
                        type="text"
                        name="name"
                        id="name"
                        value={newUser.name}
                        onChange={(e) =>
                          setNewUser({ ...newUser, name: e.target.value })
                        }
                        className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                        required
                      />
                      <label
                        htmlFor="name"
                        className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
                      >
                        Name
                      </label>
                    </div>
                    <div className="relative h-11 w-full min-w-[200px]">
                      <input
                        placeholder=""
                        type="text"
                        name="surname"
                        id="surname"
                        value={newUser.surname}
                        onChange={(e) =>
                          setNewUser({ ...newUser, surname: e.target.value })
                        }
                        className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                        required
                      />
                      <label
                        htmlFor="surname"
                        className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
                      >
                        Surname
                      </label>
                    </div>
                  </div>
                  <div className="flex flex-col md:flex-row gap-4">
                    <div className="relative h-11 w-full min-w-[200px]">
                      <input
                        placeholder=""
                        type="password"
                        name="password"
                        id="password"
                        value={newUser.password}
                        onChange={(e) =>
                          setNewUser({ ...newUser, password: e.target.value })
                        }
                        className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                        required
                      />
                      <label
                        htmlFor="password"
                        className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500"
                      >
                        Password
                      </label>
                    </div>
                  </div>
                </form>
              </Modal>
            )}
            <div className="relative  shadow-md sm:rounded-lg">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th className="px-6 py-3">Username</th>
                    <th className="px-6 py-3">Name</th>
                    <th className="px-6 py-3">Surname</th>
                    <th className="px-6 py-3">Role</th>

                    <th className="px-6 py-3">Email</th>
                    <th className="px-6 py-3">Created At</th>
                  </tr>
                </thead>

                {loading ? (
                  <LoadingTicketList />
                ) : (
                  <>
                    {users.map((user) => (
                      <tr
                        key={user.id}
                        className="bg-white border-b  text-black hover:bg-gray-50"
                      >
                        <td className="px-6 py-1">{user.username}</td>
                        <td className="px-6 py-1">{user.name}</td>
                        <td className="px-6 py-1">{user.surname}</td>
                        <td className="px-6 py-1">{user.role}</td>

                        <td className="px-6 py-1">{user.email}</td>
                        <td className="px-6 py-1">
                          {formatDate(user.registered_date)}
                        </td>
                        <td className="px-6 py-1 flex gap-2">
                          <button
                            onClick={() => startEditingUser(user)}
                            className="p-1 rounded-full bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-300"
                          >
                            <IconEdit className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteUser(user.id)}
                            className="p-1 rounded-full bg-red-500 text-white hover:bg-red-600 transition-colors duration-300"
                          >
                            <IconDelete className="w-5 h-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </table>
            </div>
          </div>
          {editingUser && (
            <Modal
              title="Edit User"
              onClose={() => setEditingUser(null)}
              onSubmit={handleEditUser}
            >
              <form onSubmit={handleEditUser} className="flex flex-col gap-4">
                <div className="flex flex-col md:flex-row gap-4">
                  <input
                    className="p-2 border border-gray-300 rounded flex-1"
                    type="text"
                    placeholder="Username"
                    value={editUser.username}
                    onChange={(e) =>
                      setEditUser({ ...editUser, username: e.target.value })
                    }
                    required
                  />
                  <input
                    className="p-2 border border-gray-300 rounded flex-1"
                    type="email"
                    placeholder="Email"
                    value={editUser.email}
                    onChange={(e) =>
                      setEditUser({ ...editUser, email: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <input
                    className="p-2 border border-gray-300 rounded flex-1"
                    type="text"
                    placeholder="Name"
                    value={editUser.name}
                    onChange={(e) =>
                      setEditUser({ ...editUser, name: e.target.value })
                    }
                    required
                  />
                  <input
                    className="p-2 border border-gray-300 rounded flex-1"
                    type="text"
                    placeholder="Surname"
                    value={editUser.surname}
                    onChange={(e) =>
                      setEditUser({ ...editUser, surname: e.target.value })
                    }
                    required
                  />
                </div>

                <div className="flex flex-col md:flex-row gap-4">
                  <input
                    className="p-2 border border-gray-300 rounded flex-1"
                    type="password"
                    placeholder="Password"
                    value={editUser.password}
                    onChange={(e) =>
                      setEditUser({ ...editUser, password: e.target.value })
                    }
                    required
                  />
                </div>
              </form>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagerUsersPage;
