import React, { useState } from "react";

import IconForward from "../components/SVG/IconForward";
import IconClose from "../components/SVG/IconClose";

const ForwardModal = ({
  isOpen,
  onClose,
  users,
  selectedUsers,
  setSelectedUsers,
  handleForwardToUsers,
  handleForwardToAllUsers,
  ticketId,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  const handleUserChange = (e) => {
    const { value, checked } = e.target;

    setSelectedUsers((prev) =>
      checked ? [...prev, value] : prev.filter((userId) => userId !== value)
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user.id.toString()));
    }
    setSelectAll(!selectAll);
  };

  const handleSubmit = () => {
    if (selectAll) {
      handleForwardToAllUsers(ticketId);
    } else {
      if (selectedUsers.length === 0) {
        alert("Please select at least one user");
        return;
      }
      handleForwardToUsers(ticketId, selectedUsers);
    }
    onClose();
  };

  return (
    isOpen && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-60">
        <div className="bg-white p-6 rounded-2xl shadow-2xl w-full max-w-lg transition-all">
          <h3 className="text-2xl font-bold text-gray-800 mb-6 text-center">
            Forward Ticket
          </h3>

          {!selectAll && (
            <div
              className={`overflow-y-auto mb-4 p-2 rounded-md border border-gray-200 ${
                users.length > 4 ? "max-h-40" : "max-h-none"
              }`}
            >
              {users.map((user) => (
                <label
                  key={user.id}
                  className="flex items-center space-x-3 p-2 hover:bg-gray-100 rounded-md transition"
                >
                  <input
                    type="checkbox"
                    value={user.id.toString()}
                    checked={selectedUsers.includes(user.id.toString())}
                    onChange={handleUserChange}
                    className="w-5 h-5 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-2 focus:ring-yellow-500"
                  />
                  <span className="text-gray-700 font-medium">{user.name}</span>
                </label>
              ))}
            </div>
          )}

          <div className="flex justify-end space-x-3 mt-6">
            <button
              onClick={handleSubmit}
              className="bg-yellow-400 text-white font-semibold py-2 px-6 rounded-lg hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 transition"
            >
              <IconForward className="w-5 h-5" />
            </button>
            <button
              onClick={onClose}
              className="bg-gray-300 text-gray-700 font-semibold py-2 px-6 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 transition"
            >
              <IconClose className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default ForwardModal;
