import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth";

import IconLogin from "../components/SVG/IconLogin";
import IconEye from "../components/SVG/IconEye";
import IconEyeInvisible from "../components/SVG/IconEyeInvisible";
import ProgressBar from "../tools/ProgressBar";
import { serverIP } from "../api";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { setToken, setUserRole, setUserId, setUserName, setUserDepartment } =
    useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const response = await fetch(`${serverIP}api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      if (response.ok) {
        const { token, user } = data;
        const { id, name, role, department, surname } = user;

        const storage = rememberMe ? localStorage : sessionStorage;

        storage.setItem("token", token);
        storage.setItem("userRole", role);
        storage.setItem("userId", id);
        storage.setItem("userName", name);
        storage.setItem("userSurname", surname);
        storage.setItem("userDepartment", department);

        setToken(token);
        setUserRole(role);
        setUserId(id);
        setUserName(name);
        setUserDepartment(department);

        if (role === "admin") {
          navigate("/admin");
        } else if (role === "manager") {
          navigate("/manager");
        } else {
          navigate("/user");
        }
      } else {
        setError(
          " " + (data.message || "Please check your credentials and try again.")
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="bg-[url('https://www.sunvalleycyprus.com/uploads/images/image-310.png')] bg-cover bg-center 
 h-[100vh] items-center flex justify-center px-5 lg:px-0"
    >
      <div className="max-w-screen-xl bg-white   sm:rounded-lg flex justify-center flex-1">
        <div className="flex-1 bg-yellow-400 text-center hidden md:flex">
          <div
            className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
            style={{
          backgroundImage: `url(https://www.sunvalleycyprus.com/uploads/images/sun-valley-cyprus-footerlogo-6627.png
)`,
            }}
          ></div>
        </div>
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
          <div className="flex flex-col items-center">
            <div className="text-center">
              <h1 className="text-2xl xl:text-4xl font-extrabold text-yellow-400">
                Login
              </h1>
              <p className="text-[18px] text-gray-500">
                Manages and tracks requests/issues.
              </p>
            </div>
            <form onSubmit={handleLogin} className="w-full flex-1 mt-6">
              <div className="mx-auto max-w-xs flex flex-col gap-4">
                {/* Username input */}
                <div className="relative h-11 w-full min-w-[200px]">
                  <input
                    placeholder=""
                    type="text"
                    name="username"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value.toLowerCase())}
                    className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                  />
                  <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Username
                  </label>
                </div>

                {/* Password input with eye icon */}
                <div className="relative h-11 w-full min-w-[200px]">
                  <input
                    placeholder=""
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-base font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-yellow-400 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100"
                  />
                  <label className="after:content[''] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[12px] font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-yellow-400 after:transition-transform after:duration-300 peer-placeholder-shown:text-base peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[12px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-yellow-400 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Password
                  </label>
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 text-gray-500 transition-transform duration-300 ease-in-out"
                  >
                    {showPassword ? (
                      <IconEye className="w-6 h-6" />
                    ) : (
                      <IconEyeInvisible className="w-6 h-6" />
                    )}
                  </button>
                </div>

                {/* Remember Me Checkbox */}
                <div className="flex items-center mt-4">
                  <input
                    type="checkbox"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    className="w-4 h-4 accent-yellow-400 border-gray-300 rounded focus:ring-yellow-500"
                  />
                  <label
                    htmlFor="rememberMe"
                    className="ml-2 text-gray-600 text-sm"
                  >
                    Remember Me
                  </label>
                </div>

                {error && (
                  <div className="text-red-500 text-center mt-4">{error}</div>
                )}

                <button
                  type="submit"
                  className=" text-xl tracking-wide font-semibold bg-yellow-400 text-gray-100 w-full py-4 rounded-lg hover:bg-yellow-500 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                  disabled={loading}
                >
                  {loading ? (
                    <svg
                      className="animate-spin h-5 w-5  text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <IconLogin />
                  )}

                  <span className="ml-2">Login</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
